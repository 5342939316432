import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import "../../common/components/css/style.css";
import "../../common/components/css/style1.css";
import axios from "axios";
import { Calendar, Views } from "react-big-calendar";
import moment from "moment-timezone";
import momentLocalizer from 'react-big-calendar/lib/localizers/moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Dropdown } from "flowbite-react";
import "../../index.css";
import { HiCheck } from "react-icons/hi";
// 12-09-2024 start
import imgEdit from "./images/edit_icon.svg";
import imgDelete from "./images/delete_icon.svg";
import imgClose from "./images/img_close.svg";
// 12-09-2024 end
import Snackbar from "@mui/material/Snackbar";
import ListItemIcon from "@mui/material/ListItemIcon";
import DiableCalendar from "./images/disable_calendar_icon.svg";
import CustomToolbar from "./ToolbarComponent";
import de from "./images/de.svg";
import CommonSidebarFooter from '../../common/layouts/CommonSidebarFooter.jsx';
import dayjs from 'dayjs';
import { DatePicker } from "antd";
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";
import ProgramAddComponent from "../programs/AddProgram.jsx";

export default function EventCalendar() {

  const navigate = useNavigate();

  const [programsData, setProgramsData] = useState([]);
  // const [program, setprogram] = useState(null);
  const [programsLoading, setProgramsLoading] = useState(false);
  const calendarRef = useRef(null);

  const fetchProgramsData = async () => {
    setProgramsLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
      };
      const { data } = await axios.post(
        baseUrl + "/api/get_programs",
        postData,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        }
      );
      setProgramsData(data.data);
      setProgramsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const localizer = momentLocalizer(moment);

  const [program, setProgram] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [category, setCategory] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const selectedCategory = categoryData.find((data) => data.id === category);
  const fetchCategoryData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/get_event_category",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setCategoryData(response.data.data);
        console.log(response);
      }
    });
  };

  const [foodTimeData, setFoodTimeData] = useState([]);
  const selectedStartTime = foodTimeData.find((data) => data.id === startTime);
  const selectedEndTime = foodTimeData.find((data) => data.id === endTime);
  const fetchFoodTimeData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/get_time",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setFoodTimeData(response.data.data);
        console.log(response);
      }
    });
  };

  const selectedProgram = programsData.find((data) => data.id === program);

  const [events, setEvents] = useState([]);
  const [showEventPopup, setShowEventPopup] = useState(false);
  const [showAddEventPopup, setShowAddEventPopup] = useState(false);
  const [newEvent, setNewEvent] = useState({
    id: "",
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    program: "",
    category: "",
    notify: true,
    description: "",
  });
  /* 28-8-2024 */
  const [createEvent, setCreateEvent] = useState({
    id: "",
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
  });
  const [addUpdateStatus, setAddUpdateStatus] = useState("");
  /* 31-07-2024 */
  const [currentView, setCurrentView] = useState(() => {
    return localStorage.getItem('currentView') || Views.WEEK;
  });
  const [currentDate, setCurrentDate] = useState(() => {
    const savedDate = localStorage.getItem('calendarDate');
    return savedDate ? new Date(savedDate) : new Date();
  });

  useEffect(() => {
    localStorage.setItem('calendarDate', currentDate);
  }, [currentDate]);

  /* 28-8-2024 */

  useEffect(() => {
    console.log("newStart", newEvent);
  }, [newEvent]);

  useEffect(() => {
    console.log("createStart", createEvent);
  }, [createEvent]);

  const handleViewChange = (view) => {
    setCurrentView(view);
    localStorage.setItem('currentView', view);
  };

  useEffect(() => {
    const storedView = localStorage.getItem('currentView');
    if (storedView && storedView !== currentView) {
      setCurrentView(storedView);
    }
  }, [currentView]);

  useEffect(() => {
    localStorage.setItem('currentView', currentView);
    console.log("Current view is now:", currentView);
  }, [currentView]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('currentView');
    };
  }, []);

  /* 29-8-2024 */
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null);

  const formatCalendarDate1 = (date) => {
    if (date) {
      return date
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");
    }
    return null;
  };

  const formatCalendarDate = (date) => {
    // Check if date is already a Date object
    const validDate = typeof date === "string" ? new Date(date) : date;

    // Check if validDate is indeed a valid Date instance
    if (validDate instanceof Date && !isNaN(validDate)) {
      return validDate
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");
    }

    return null;
  };


  const handleSelectSlot = async ({ start, end }) => {
    setEndTimeError(null);
    setStartTimeError(null);
    setShowEventPopup(false);
    setAddUpdateStatus("add");
    setCategory("");
    setEndTime("");
    setStartTime("");
    setProgram(null);
    /* 29-8-2024 */
    if (selectedDate) {
      setEvents((prevEvents) =>
        prevEvents.filter(event => event.id !== '')
      );
    }
    const adjustedEnd = new Date(end);
    adjustedEnd.setDate(adjustedEnd.getDate() - 1);
    const newEvent = {
      id: "",
      title: "",
      start: start ? formatCalendarDate(start) : '',
      end: currentView === "month" ? (adjustedEnd ? formatCalendarDate(adjustedEnd) : '') : end ?? '',
      startTime: "",
      endTime: "",
      program: "",
      category: "",
      notify: true,
      description: "",
    };
    const createEvent = {
      id: "",
      title: "",
      start: start,
      end: currentView === "month" ? (adjustedEnd ? formatCalendarDate(adjustedEnd) : '') : end ?? '',
      startTime: "",
      endTime: "",
    };
    setSelectedDate(newEvent.start);
    setCreateEvent(createEvent);
    setNewEvent(newEvent);
    setShowAddEventPopup(true);
    setEvents((prevEvents) => [...prevEvents, createEvent]);
  };

  useEffect(() => {
    console.log("selectedDate", selectedDate);
  }, [selectedDate])

  const handleFormEmpty = () => {
    setTitleError(null);
    setProgram(null);
    setProgramError(null);
    setCategoryError(null);
    setDescriptionError(null);
    const newEvent = {
      id: "",
      title: "",
      start: "",
      end: "",
      startTime: "",
      endTime: "",
      program: "",
      category: "",
      notify: true,
      description: "",
    };
    const createEvent = {
      id: "",
      title: "",
      start: "",
      end: "",
      startTime: "",
      endTime: "",
    };
    setCreateEvent(createEvent);
    setNewEvent(newEvent);
    setEvents(filterEventsWithId(events));
  }

  const [titleError, setTitleError] = useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [startTimeError, setStartTimeError] = useState(null);
  const [endTimeError, setEndTimeError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [programError, setProgramError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  /* 29-8-2024 */
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const handleAddEvent = () => {
    let hasError = false;

    if (newEvent.title === "") {
      setTitleError("Please Enter Event Name");
      //26.07.2024
      hasError = true;
    } else {
      setTitleError(null);
    }
    if (!category) {
      setCategoryError("Please Select a Category");  //26.07.2024
      hasError = true;
    } else {
      setCategoryError(null);
    }
    if (program === null) {
      setProgramError("Please Select a Program");  //26.07.2024
      hasError = true;
    } else {
      setProgramError(null);
    }
    // if (newEvent.description === "") {
    //   setDescriptionError("Please Select a Description");
    //   hasError = true;
    // } else {
    //   setDescriptionError(null);
    // }
    if (selectedCategory && selectedCategory.id == 1) {
      if (startTime === "") {
        setStartTimeError("Please Select Start Time");  //26.07.2024
        hasError = true;
      } else {
        setStartTimeError(null);
      }
      if (endTime === "") {
        setEndTimeError("Please Select End Time");  //26.07.2024
        hasError = true;
      } else {
        setEndTimeError(null);
      }
    }

    if (selectedCategory && selectedCategory.id == 2) {
      if (newEvent.start && newEvent.end) {
        const startDate = new Date(newEvent.start);
        const endDate = new Date(newEvent.end);

        if (startDate > endDate) {
          setSnackbarMessage("End Date cannot be less than Start Date.");
          setMessageType("error");
          handleSnackbarOpen();
          return;
        } else if (newEvent.end < newEvent.start) {
          setEndDateError("Please Select End Date");  //26.07.2024
          hasError = true;
        } else {
          setEndDateError(null);
        }
      }
    }

    if (hasError) return;

    //26.07.2024
    const formattedStartDate = selectedCategory && selectedCategory.id == 2
      ? `${newEvent.start} ${selectedStartTime ? selectedStartTime?.time_name : "08:00 AM"}`
      : `${newEvent.start} ${selectedStartTime ? selectedStartTime?.time_name : "00:00"}`;

    const formattedEndDate = selectedCategory && selectedCategory.id == 2
      ? `${newEvent.end} ${selectedEndTime ? selectedEndTime?.time_name : "08:00 PM"}`
      : `${newEvent.end} ${selectedEndTime ? selectedEndTime?.time_name : "00:00"}`;
    console.log("formattedStartDate", formattedStartDate)
    console.log("formattedEndDate", formattedEndDate)
    const start_time = selectedCategory && selectedCategory.id == 2 ? 1 : startTime;
    const end_time = selectedCategory && selectedCategory.id == 2 ? 48 : endTime;

    const postData = {
      school_id: localStorage.getItem("schoolId"),
      event_type: category,
      programs_id: program,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      description: newEvent.description,
      title: newEvent.title,
      from_time_id: start_time, //26.07.2024
      to_time_id: end_time,     //26.07.2024
      calendar_status: 1,
      status: 1,
      notify: newEvent.notify == true ? 1 : 0 /* 11-09-2024 */
    };

    if (newEvent.id && newEvent.id !== "") {
      postData.id = newEvent.id;
      postData.updated_by = localStorage.getItem("loginUserId");
    } else {
      postData.created_by = localStorage.getItem("loginUserId");
    }
    setTitleError(null);
    setStartTimeError(null);
    setEndTimeError(null);
    console.log(postData);
    axios({
      method: "post",
      url: `${baseUrl}/api/timetable_add_edit`,
      data: postData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          /* 11-09-2024 start*/
          setShowAddEventPopup(false);
          setSnackbarMessage(response.data.message);
          setMessageType("success");
          handleSnackbarOpen();
          fetchCalendarData();
          if (newEvent.id === "") {
            setRefreshNotification(true);
          }
          const newEventStartDate = moment(response.data.data.start_date, 'YYYY-MM-DD hh:mm A');
          setCurrentMonth(newEventStartDate);
          /* 11-09-2024 end*/
        } else {
          setSnackbarMessage(response.data.message);
          setMessageType("error");
          handleSnackbarOpen();
        }
        setSelectedDate(null);
      })
      .catch((error) => {
        console.error("There was an error adding/updating the event!", error);
      });
  };
  {/* 26-8-24 */ }
  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    return `${day}-${month}-${year}`;
  };
  const [deleteEventId, setDeleteEventId] = useState(null);
  const handleDeleteEvent = () => {
    axios({
      method: "post",
      url: `${baseUrl}/api/timetable_delete`,
      data: {
        id: deleteEventId,
        updated_by: localStorage.getItem("loginUserId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setSnackbarMessage(response.data.message);
          setMessageType("success");
          handleSnackbarOpen();
          handleRecycleBinClose();
          fetchCalendarData();
        } else {
          setSnackbarMessage(response.data.message);
          setMessageType("error");
          handleSnackbarOpen();
        }
      })
      .catch((error) => {
        console.error("There was an error adding/updating the event!", error);
      });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      [name]: newValue,
    }));
    if (name === "start" || name === "end" || name === "title") {
      setCreateEvent((prevEvent) => ({
        ...prevEvent,
        [name]: newValue,
      }));
    }
  };

  //26.07.2024
  useEffect(() => {
    if (newEvent.start && newEvent.end) {
      const startDate = new Date(newEvent.start);
      const endDate = new Date(newEvent.end);

      if (startDate > endDate) {
        setSnackbarMessage("End Date cannot be less than Start Date.");
        setMessageType("error");
        handleSnackbarOpen();
        return;
      }
    }
  }, [newEvent.start, newEvent.end]);
  //26.07.2024
  useEffect(() => {
    console.log("newEvent state updated:", newEvent.start, newEvent.end);
  }, [newEvent]);

  /* 29-8-2024 */
  const handleSelecting = ({ start, end }) => {
    setSelectedRange({ start, end });
    return true;
  };

  const handleSelectEvent = (event) => {
    setEndTimeError(null);
    setStartTimeError(null);
    setShowAddEventPopup(false);
    setAddUpdateStatus("update");
    setNewEvent({});
    console.log(event);
    // const {start, end } = event;
    setCategory(event.event_type);
    setEndTime(event.to_time_id);
    setStartTime(event.from_time_id);
    setProgram(event.program_id);
    const newEvent = {
      id: event.id,
      title: event.title,
      start: event.start ? formatCalendarDate(event.start) : '',
      end: event.end ? formatCalendarDate(event.end) : '',
      startTime: event.from_time_id,
      endTime: event.to_time_id,
      program: event.program_id,
      category: event.event_type,
      notify: true,
      description: event.description,
      title: event.title,
      calendar_status: event.calendar_status,
    };
    const createEvent = {
      title: event.title,
      start: event.start ? formatCalendarDate(event.start) : '',
      end: event.end ? formatCalendarDate(event.end) : '',
      startTime: event.from_time_id,
      endTime: event.to_time_id,
    }
    setCreateEvent(createEvent);
    setNewEvent(newEvent);
    if (event.title) setShowEventPopup(true);
    // setShowAddEventPopup(true);
  };

  const [calendarData, setCalendarData] = useState([]);
  const [calendarLoading, setCalendarLoading] = useState(false);

  const fetchCalendarData = async () => {
    setCalendarLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
      };
      const { data } = await axios.post(
        baseUrl + "/api/get_timetable",
        postData,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        }
      );
      {/* 11-09-2024 Start*/ }
      setCalendarData(data.data);
      const fetchedEvents = data.data.map((event) => {
        const [startYear, startMonth, startDay, startHour, startMinute] = event.start_date;
        const [endYear, endMonth, endDay, endHour, endMinute] = event.end_date;

        let startDate = new Date(startYear, startMonth, startDay, startHour, startMinute);
        let endDate = new Date(endYear, endMonth, endDay, endHour, endMinute);

        if (startHour === 0 && startMinute === 0) {
          startDate.setHours(8, 0, 0);
        }
        if (endHour === 0 && endMinute === 0) {
          endDate.setHours(20, 0, 0);
        }

        return {
          id: event.id,
          title: event.title,
          event_type: event.event_type,
          program_id: event.programs_id,
          from_time_id: event.from_time_id,
          to_time_id: event.to_time_id,
          description: event.description,
          title: event.title,
          calendar_status: event.calendar_status,
          start: startDate,
          end: endDate,
        };
      });
      setEvents(fetchedEvents);
    } catch (error) {
      console.log(error);
    } finally {
      setCalendarLoading(true);
    }
  };
  {/* 11-09-2024 end*/ }


  useEffect(() => {
    console.log("Event:_------", events);
  }, [events]);

  const [minTime, maxTime] = useMemo(() => {
    let earliestStart = new Date();
    let latestEnd = new Date();
    events.forEach(event => {
      if (event.start < earliestStart) earliestStart = event.start;
      if (event.end > latestEnd) latestEnd = event.end;
    });
    const min = new Date(earliestStart);
    min.setHours(earliestStart.getHours() - 1);

    const max = new Date(latestEnd);
    max.setHours(latestEnd.getHours() + 1);
    return [min, max];
  }, [events]);

  // ------11-06----------------
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };
  useEffect(() => { }, [snackbarMessage, messageType]);

  // ----17-06--------------------
  // const [checkedState, setCheckedState] = useState(
  //     new Array(programsData.length).fill(false)
  // );
  // const [checkedIds, setCheckedIds] = useState([]);
  // const [dropdownOpen, setDropdownOpen] = useState(false);

  // const handleCheckboxChange = (index) => {
  //     const updatedCheckedState = [...checkedState];
  //     updatedCheckedState[index] = !updatedCheckedState[index];
  //     setCheckedState(updatedCheckedState);

  //     const id = programsData[index].id;
  //     if (updatedCheckedState[index]) {
  //         setCheckedIds((prevCheckedIds) => [...prevCheckedIds, id]);
  //     } else {
  //         setCheckedIds((prevCheckedIds) =>
  //             prevCheckedIds.filter((checkedId) => checkedId !== id)
  //         );
  //     }
  // };

  // const toggleDropdown = () => {
  //     setDropdownOpen(!dropdownOpen);
  // };

  const capitalize = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // const Event = ({ event }) => {
  //   const startTime = moment(event.start).format("h:mm A");
  //   const endTime = moment(event.end).format("h:mm A");
  //   const formattedTitle = capitalize(event.title);

  //   return (
  //     <span>
  //       <strong>{formattedTitle}</strong>
  //       <br />
  //       <br />
  //       {currentView === "month" ? `${startTime} - ${endTime}` : null}
  //     </span>
  //   );
  // };

  /* 31-8-2024 */
  const CustomEvent = ({ event }) => {
    const startTime = moment(event.start).format("h:mm A");
    const endTime = moment(event.end).format("h:mm A");
    const formattedTitle = event.title.charAt(0).toUpperCase() + event.title.slice(1);
    let isNewEvent = event.id === '';
    const containerClass = isNewEvent ? 'custom-event-content' : 'rbc-event-content';
    const eventClass = isNewEvent ? 'new-event' : 'custom-event';
    let start = startTime === "12:00 AM" ? "08:00 AM" : startTime;
    let end = endTime === "12:00 AM" ? "08:00 PM" : endTime;
    console.log("CustomEvent_____", start, end);
    console.log("CustomEvent_____1", startTime, endTime);
    console.log("Event_____1", event);
    return (
      <div className={containerClass}>
        <div className={eventClass}>
          <div className="event-title">{formattedTitle}</div>
          <br />
          <div className="event-dates">{`${start} to ${end}`}</div>
        </div>
      </div>
    );
  };

  const onEndChange = (date, dateString) => {
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      end: date.format('YYYY-MM-DD'),
    }));
    setCreateEvent((prevEvent) => ({
      ...prevEvent,
      end: date.format('YYYY-MM-DD'),
    }));
  };

  const filterEventsWithId = (events) => {
    return events.filter(event => event.id !== '');
  };

  const handleCreatEventTime = (time, item) => {
    setCreateEvent((prevEvent) => ({
      ...prevEvent,
      [time]: item?.time_name,
    }));
  }

  // -------------------------19-06-------------------------

  const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
  const [newProgramAddUpdateStatus, setNewProgramAddUpdateStatus] = useState(null);
  const handleOpenNewProgramPopupVisible = (status) => {
    setNewProgramAddUpdateStatus(status);
  }

  const [programStartTime, setProgramStartTime] = useState("");
  const [programEndTime, setProgramEndTime] = useState("");
  const [programTeacher, setTeacher] = useState("");
  const [timeData, setTimeData] = useState([]);
  const [teachersData, setTeachersData] = useState([]);
  const selectedPStartTime = timeData.find((data) => data.id === programStartTime);
  const selectedPEndTime = timeData.find((data) => data.id === programEndTime);
  const selectedTeachers = teachersData.find(
    (data) => data.id === programTeacher
  );


  const fetchTeachersData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/teacher_list",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setTeachersData(response.data.data);
        console.log(response);
      }
    });
  };

  useEffect(() => {
    fetchCategoryData();
    fetchFoodTimeData();
    fetchProgramsData();
    fetchTeachersData();
    fetchCalendarData();
  }, []);

  /* 28-8-2024 */
  /* 08-10-24 start */
  // Check if the provided date is the selected date
  const isDateSelected = (date) => {
    return dayjs(date).isSame(dayjs(selectedDate), 'day');
  };

  // dayPropGetter for month view
  const customDayPropGetter = (date) => {
    const className = isDateSelected(date) ? 'selected-date' : '';
    return { className };
  };

  // eventPropGetter for week view
  const eventPropGetter = (event, start, end, isSelected) => {
    const eventDate = dayjs(event.start).startOf('day');
    const selectedDateValue = dayjs(selectedDate).startOf('day');

    // Add class to the event if it matches the selected date
    return {
      className: eventDate.isSame(selectedDateValue) ? 'selected-date' : '',
    };
  };
  /* 08-10-24 end */

  const CustomTimeGutterHeader = () => {
    return (
      <div className="custom-time-gutter-header">
        <i class="fa-regular fa-clock"></i>
      </div>
    );
  };

  // ----------------------06-07---------------------

  const categoryInputRef = useRef(null);
  const startTimeInputRef = useRef(null);
  const endTimeInputRef = useRef(null);
  const eventProgramInputRef = useRef(null);

  const initialBorderColor = {
    categoryBorder: "",
    startTimeBorder: "",
    endTimeBorder: "",
    eventProgramBorder: ""
  };

  const [borderColor, setBorderColor] = useState(initialBorderColor);

  const handleFocus = (borderColor) => {
    if (borderColor === "category") {
      setBorderColor({ ...borderColor, categoryBorder: "#4D6EE3" });
    } else if (borderColor === "startTime") {
      setBorderColor({ ...borderColor, startTimeBorder: "#4D6EE3" });
    } else if (borderColor === "endTime") {
      setBorderColor({ ...borderColor, endTimeBorder: "#4D6EE3" });
    } else if (borderColor === "programs") {
      setBorderColor({ ...borderColor, eventProgramBorder: "#4D6EE3" });
    }
  };

  const handleBlur = () => {
    setBorderColor(initialBorderColor);
  };

  const handleClickOutside1 = (event) => {
    if (categoryInputRef.current && !categoryInputRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (startTimeInputRef.current && !startTimeInputRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (endTimeInputRef.current && !endTimeInputRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (eventProgramInputRef.current && !eventProgramInputRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside1);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside1);
    };
  }, []);

  // ---------------------24-07-------------------
  const [isRecycleBinVisible, setRecycleBinVisible] = useState(false);
  const handleRecycleBinClick = (deleteId) => {
    setDeleteEventId(deleteId);
    setRecycleBinVisible(true);
  };

  const handleRecycleBinClose = () => {
    setDeleteEventId(null);
    setRecycleBinVisible(false);
  };

  const [refreshNotification, setRefreshNotification] = useState(false);
  const dateFormatList = 'DD-MM-YYYY';

  return (
    <section className="event_calc_cus_st staff_atten_cus eventvalc_cus">
      <Snackbar className={`snack_total ${messageType === 'success' ? "snackbar_success" : "snackbar_failure"}`}
        anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <div>
                {messageType === 'success' ?
                  <span class="icon-List_Present" style={{ fontSize: " 21px;" }}>
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </span> :
                  <span class="icon-List_Absent" style={{ fontSize: " 21px;" }}>
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                    <span class="path6"></span>
                  </span>
                }
              </div>
              {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
            </ListItemIcon>
            <div className="success_cuc">
              <p> {snackbarMessage} </p>
            </div>
          </div>
        }
        // ContentProps={{
        //     sx: {
        //         border: "1px solid",
        //         opacity: "1",
        //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
        //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
        //         width: 'auto',
        //         minWidth:'auto'
        //     }
        // }}
        autoHideDuration={3000}
      />
      <div className="outer-container">
        <div className="overlay" />
        <div id="left-panel" className="sp-left-panel">
          <CommonSidebarHeader />
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="name_tag_side">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    <span>
                      <i className="icon-Dashboard" />
                      <p>Dashboard</p>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/activity");
                    }}
                  >
                    <span>
                      <i className="icon-Activity" />
                      <p>Activity</p>
                    </span>
                  </li>
                  <li className="selected">
                    <span>
                      <i className="icon-Timetable" />
                      <p>Calendar</p>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/staff_list");
                    }}
                  >
                    <span>
                      <i className="icon-Staff" />
                      <p>Staff</p>
                    </span>
                  </li>
                  {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                </ul>
                <h4
                  className="sp-title flexc justifySp"
                  onClick={() => handleOpenNewProgramPopupVisible("add")}
                >
                  Programs{" "}
                  <span id="add-programs" className="sp-plus">
                    +
                  </span>
                </h4>
                <ul>
                  {programsLoading &&
                    programsData &&
                    programsData.length > 0 ? (
                    programsData.map((data, index) => {
                      return (
                        <li
                          onClick={() => {
                            localStorage.setItem("programId", data.id);
                            localStorage.setItem(
                              "programName",
                              data?.program_name
                            );
                            navigate("/student_list");
                          }}
                        >
                          <span>
                            <i className="icon-Program_1" />
                            <p>{data?.program_name}</p>
                          </span>
                        </li>
                      );
                    })
                  ) : (
                    <div className="data-loader">
                      <Loader
                        type="spinner-cub"
                        bgColor="#4D6EE3"
                        color="#6B6F8C"
                        title="Loading..."
                        size={35}
                      />
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <CommonSidebarFooter refreshNotification={refreshNotification} setRefreshNotification={setRefreshNotification} />
            {/* <CommonSidebarFooter /> */}
          </div>
        </div>
        {/* my code */}
        <div className="sp-right-panel">
          <div className="sp-top-bar flexc justifySp borderbt">
            <div className="ft-wt600 flexc">
              <span className="selected-icon">
                <i className="icon-Timetable-Outline" />
              </span>
              Calendar
              {/* <i className="icon-More-Icon" /> */}
            </div>
            {/* <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc">
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                        </div> */}
          </div>
          {calendarLoading ? (
            <div className="sp-timetab-inr">
              <Calendar
                popup //25.07.2024 - pop-up for show more
                localizer={localizer}
                events={events}
                onView={handleViewChange}
                startAccessor="start"
                endAccessor="end"
                selectable
                views={["month", "week"]}
                view={currentView} /* 31-07-2024 */
                date={currentMonth} /* 29-8-2024 */
                components={{
                  month: {
                    event: (props) => <CustomEvent {...props} currentView={currentView} />,
                  },
                  week: {
                    event: (props) => <CustomEvent {...props} currentView={currentView} />,
                    timeGutterHeader: CustomTimeGutterHeader,
                  },
                  toolbar: CustomToolbar,
                }}
                scrollToTime={new Date(1970, 1, 1, 8)}
                min={new Date(1970, 1, 1, 8)}
                max={new Date(1970, 1, 1, 21)} /* 31-8-2024 */
                /* 29-8-2024 */
                onNavigate={(newDate) => {
                  setCurrentMonth(newDate);
                }}
                dayPropGetter={customDayPropGetter}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                onSelecting={handleSelecting} /* 29-8-2024 */
                showMultiDayTimes={true} /* 29-8-2024 */
                // Custom allDayAccessor function
                allDayAccessor={(event) => {
                  return event.start === event.end;  // Treat same start and end time as all-day event
                }}
                eventPropGetter={eventPropGetter} /* 08-10-24 */
              />
            </div>
          ) : (
            <div className="data-loader">
              <Loader
                type="spinner-cub"
                bgColor="#4D6EE3"
                color="#6B6F8C"
                title="Loading..."
                size={35}
              />
            </div>
          )}
        </div>
        {showAddEventPopup && (
          <div
            className="dialog-model absolute create_cus_data"
            style={newEvent.start && { display: "block" }}
          >
            <div
              className="sp-tt ft-14 flexc ft-wt700"
              style={{ fontSize: "20px" }}
            >
              {addUpdateStatus === "add" ? "Create New" : "Update"} Event
            </div>
            <div className="form-section-default flexc justifySp fwrap">
              <div className="field-label fullsz-field error_mes_cus pos_stu_cus">
                <label>Event<span className="mandatory">*</span></label>
                <div className="field-value-holder margin_top10p">
                  <input
                    className={`sp-field-input ${newEvent.title == "" && titleError ? 'error' : ''}`}
                    placeholder="Type event.."
                    type="text"
                    name="title"
                    value={newEvent.title}
                    onChange={handleChange}
                    style={{ height: "40px" }}
                  />
                </div>
                {newEvent.title === "" && titleError && <p className="error">{titleError}</p>}
              </div>
              <div className="field-label half-field error_mes_cus">
                <label>Category<span className="mandatory">*</span></label>
                <div
                  className="field-value-holder margin_top10p dropbutton"
                  ref={categoryInputRef}
                  style={{
                    width: "100%",
                    borderColor: !category && categoryError ? "red" : borderColor.categoryBorder,
                  }}
                  onFocus={() => handleFocus("category")}
                  onBlur={handleBlur}
                >
                  <Dropdown
                    label={
                      <span
                        className={`${selectedCategory ? "selected-item" : ""}`}
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          whiteSpace: "nowrap",

                          color: selectedCategory ? '#1B252F' : '#292929',
                        }}
                      >
                        {selectedCategory
                          ? selectedCategory?.event_category_name
                          : "Select Category"}
                      </span>
                    }
                    /* 26.08.2024 */
                    disabled={newEvent.calendar_status === 0}
                  //     label={<span style={{
                  //         color: '#1B252F',
                  //         whiteSpace: 'nowrap',
                  //         fontWeight: "500",
                  //         fontSize: "13px",
                  //     }}>{selectedCategory ? selectedCategory.event_category_name : "Select Category"}</span>}
                  //     style={{ width: '100%' }}
                  >
                    {categoryData.slice(0, 2).map((item) => (
                      <Dropdown.Item
                        className={`${selectedCategory && selectedCategory.id === item.id
                          ? "selected-item dd_select"
                          : ""
                          }`}
                        // className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                        icon={
                          selectedCategory && selectedCategory.id === item.id
                            ? HiCheck
                            : null
                        }
                        onClick={() => setCategory(item.id)}
                        key={item.id}
                      >
                        {item?.event_category_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
                {!category && categoryError && <p className="error">{categoryError}</p>}
              </div>

              <div className="field-label half-field error_mes_cus">
                <label>Programs<span className="mandatory">*</span></label>
                <div
                  //29-8-2024
                  className="field-value-holder margin_top10p dropbutton program"
                  ref={eventProgramInputRef}
                  style={{
                    width: "100%",
                    borderColor: program == null && programError ? "red" : borderColor.eventProgramBorder,
                  }}
                  onFocus={() => handleFocus("program")}
                  onBlur={handleBlur}
                >

                  {/* <div
                  className="field-value-holder margin_top10p dropbutton"
                  style={{
                    width: "100%",
                    borderColor: program == null && programError ? "red" : "#D9DCE6",
                  }}
                > */}
                  {/* <Dropdown
                    label={
                      <span
                        className={`${selectedProgram ? "selected-item" : ""}`}
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          whiteSpace: "nowrap",

                          color: selectedProgram ? "#1B252F" : "#A9AFC3",
                        }}
                      >
                        {selectedProgram
                          ? selectedProgram.program_name
                          : "Select Category"}
                      </span>
                    }

                  //   label={
                  //   <span style={{ color: '#1B252F', whiteSpace: 'nowrap' }}>
                  //     {selectedProgram ? selectedProgram.program_name
                  //      : program === 0 ? "All Programs" : "Select Program"}</span>}
                  //     style={{ width: '100%' }}
                  >
                    <Dropdown.Item
                      className={`${program === 0 ? "selected-item dd_select" : ""
                        }`}
                      // className={program === 0 ? "dd_select" : null}
                      icon={program === 0 ? HiCheck : null}
                      onClick={() => setProgram(0)}
                      key={0}
                    >
                      All Programs
                    </Dropdown.Item>
                    {programsData.map((item) => (
                      <Dropdown.Item
                        className={`${selectedProgram && selectedProgram.id === item.id
                          ? "selected-item dd_select"
                          : ""
                          }`}
                        // className={selectedProgram && selectedProgram.id === item.id ? "dd_select" : null}
                        icon={
                          selectedProgram && selectedProgram.id === item.id
                            ? HiCheck
                            : null
                        }
                        onClick={() => setProgram(item.id)}
                        key={item.id}
                      >
                        {item.program_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown> */}
                  <Dropdown
                    label={
                      <span
                        className={`${selectedProgram ? "selected-item" : ""}`}
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          whiteSpace: "nowrap",
                          color: program !== null ? '#1B252F' : '#292929',
                        }}
                      >
                        {selectedProgram
                          ? selectedProgram?.program_name
                          : program === 0
                            ? "All Programs"
                            : "Select Program"}
                      </span>
                    }
                    /* 26.08.2024 */
                    disabled={newEvent.calendar_status === 0}
                  >
                    <Dropdown.Item
                      className={`${program === 0 ? "selected-item dd_select" : ""}`}
                      icon={program === 0 ? HiCheck : null}
                      onClick={() => {
                        setProgram(0);
                      }}
                      key={0}
                    >
                      All Programs
                    </Dropdown.Item>
                    {programsData.map((item) => (
                      <Dropdown.Item
                        className={`${selectedProgram && selectedProgram.id === item.id ? "selected-item dd_select" : ""}`}
                        icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                        onClick={() => {
                          setProgram(item.id);
                        }}
                        key={item.id}
                      >
                        {item?.program_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
                {program === null && programError && <p className="error">{programError}</p>}
              </div>

              {/* 26.07.2024 */}
              {selectedCategory && selectedCategory.id == 2 && (<><div className="field-label half-field">
                <label>Start Date<span className="mandatory">*</span></label>
                <div className="field-value-holder margin_top10p flexc pos_icon_date_st disable-data-field">
                  <input
                    type="text"
                    className="sp-field-input date"
                    placeholder="Start Date"
                    name="start"
                    value={moment(newEvent.start).format("DD-MM-YYYY")}
                    onChange={handleChange}
                    // readOnly
                    disabled
                    style={{
                      fontSize: "13px",
                      fontWeight: "500", color: "#A9AFC3", height: "40px"
                    }} />
                  <img src={DiableCalendar} alt="calendar" />
                  {/* {/* <i class="fa-regular fa-calendar" style={{ color: "grey" }}></i> */}
                  {/* <i class="fa-regular fa-clock" style={{ color: "grey" }}></i> */}
                </div>
              </div></>)}
              {/* 26.07.2024 */}
              {selectedCategory && selectedCategory.id == 1 && (<div className="field-label half-field error_mes_cus dis_n_drop">
                <label>Start Time<span className="mandatory">*</span></label>
                <div
                  className="field-value-holder margin_top10p flexc custom"
                  ref={startTimeInputRef}
                  style={{
                    width: "100%",
                    borderColor: startTime === "" && startTimeError ? "red" : borderColor.startTimeBorder,
                  }}
                  onFocus={() => handleFocus("startTime")}
                  onBlur={handleBlur}
                >
                  <Dropdown
                    label={<span
                      className={`flex items-center ${selectedStartTime ? "selected-item" : ""}`}
                      style={{
                        fontSize: "13px",
                        color: selectedStartTime ? '#1B252F' : '#292929',
                        width: "100%",
                        justifyContent: "space-between"
                      }}
                    >
                      <span className="time-value">{selectedStartTime ? selectedStartTime?.time_name : "Select a Time"}</span>
                      <i className="fa-regular fa-clock"></i>
                    </span>}
                    // label={<span style={{ color: 'black' }}>{selectedStartTime ? selectedStartTime.time_name : "Select Time"}</span>}
                    style={{ width: "100%" }}
                  >
                    {foodTimeData.map((item) => (
                      <Dropdown.Item
                        className={`${selectedStartTime && selectedStartTime.id === item.id
                          ? "selected-item dd_select"
                          : ""}`}
                        // className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}
                        icon={selectedStartTime && selectedStartTime.id === item.id
                          ? HiCheck
                          : null}
                        onClick={() => {
                          setStartTime(item.id);
                          handleCreatEventTime("startTime", item); /* 27-8-2024 */
                        }}
                        key={item.id}
                      >
                        {item?.time_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
                {startTime === "" && startTimeError && <p className="error">{startTimeError}</p>}
              </div>)}

              {/* 26.07.2024 */}
              {selectedCategory && selectedCategory.id == 2 && (<div className="field-label half-field disable-data-field">
                <label>End Date<span className="mandatory">*</span></label>
                <div className="field-value-holder margin_top10p flexc pos_icon_date_st end-date">
                  {/* <input
                    type="date"
                    className="sp-field-input date"
                    placeholder="End Date"
                    name="end"
                    value={moment(newEvent.end).format("YYYY-MM-DD")}
                    onChange={handleChange}
                    onClick={(e) => e.currentTarget.showPicker()}
                    // readOnly
                    style={{
                      fontSize: "13px",
                      fontWeight: "500", color: "#000", height: "40px"
                    }}
                  /> */}
                  {/* 11-09-2024 Start*/}
                  <DatePicker
                    className="event-calendar"
                    placeholder="DD/MM/YYYY"
                    format={dateFormatList}
                    name="end"
                    onChange={onEndChange}
                    value={newEvent.end ? dayjs(newEvent.end, 'YYYY-MM-DD') : null}
                    defaultPickerValue={newEvent.end ? dayjs(newEvent.end, 'YYYY-MM-DD') : null}
                  >
                  </DatePicker>
                  {/* 11-09-2024 End*/}
                  <img src={DiableCalendar} alt="calendar" />
                  {/* <i class="fa-regular fa-calendar" style={{ color: "grey" }}></i>
                  <i class="fa-regular fa-clock" style={{ color: "grey" }}></i> */}
                </div>
                {newEvent.end === newEvent.start && endDateError && <p className="error" style={{ marginTop: "5px" }}>{endDateError}</p>}
              </div>)}

              {/* 26.07.2024 */}
              {selectedCategory && selectedCategory.id == 1 && (<div className="field-label half-field error_mes_cus dis_n_drop">
                <label>End Time<span className="mandatory">*</span></label>
                <div
                  className="field-value-holder margin_top10p flexc custom"
                  ref={endTimeInputRef}
                  style={{
                    width: "100%",
                    borderColor: endTime === "" && endTimeError ? "red" : borderColor.endTimeBorder,
                  }}
                  onFocus={() => handleFocus("endTime")}
                  onBlur={handleBlur}
                >
                  <Dropdown
                    label={
                      <span
                        className={`flex items-center ${selectedEndTime ? "selected-item" : ""}`}
                        style={{
                          fontSize: "13px",
                          color: selectedEndTime ? "#1B252F" : "#292929",
                          width: "100%",
                          justifyContent: "space-between"
                        }}
                      >
                        <span className="time-value">{selectedEndTime ? selectedEndTime?.time_name : "Select a Time"}</span>
                        <i className="fa-regular fa-clock"></i>
                      </span>
                    }
                    style={{ width: '100%', height: "40px", }}
                  >
                    {foodTimeData.map(item => (
                      <Dropdown.Item
                        className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""}`}
                        icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                        onClick={() => {
                          setEndTime(item.id);
                          handleCreatEventTime("endTime", item); /* 27-8-2024 */
                        }}
                        key={item.id}
                      >
                        {item?.time_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
                {/* <div
                  className="field-value-holder margin_top10p flexc custom"
                  ref={endTimeInputRef}
                  style={{
                    width: "100%",
                    borderColor: endTime === "" && endTimeError ? "red" : borderColor.endTimeBorder,
                  }}
                  onFocus={() => handleFocus("endTime")}
                  onBlur={handleBlur}
                >
                  <Dropdown
                    label={
                      <span
                        className={`flex items-center ${selectedEndTime ? "selected-item" : ""}`}
                        style={{
                          fontSize: "13px",
                          color: selectedEndTime ? '#1B252F' : '#A9AFC3',
                          width: "100%",
                          justifyContent: "space-between"
                        }}
                      >
                        <span className="time-value">{selectedEndTime ? selectedEndTime.time_name : "Select a Time"}</span>
                        <i className="fa-regular fa-clock"></i>
                      </span>
                    }
                    style={{ width: "100%", height: "40px" }}
                  >
                    {foodTimeData.map((item) => (
                      <Dropdown.Item
                        className={`${selectedEndTime && selectedEndTime.id === item.id
                          ? "selected-item dd_select"
                          : ""
                          }`}
                        icon={
                          selectedEndTime && selectedEndTime.id === item.id ? (
                            <i className="fa-regular fa-clock"></i>
                          ) : null
                        }
                        onClick={() => setEndTime(item.id)}
                        key={item.id}
                      >
                        {item.time_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div> */}
                {endTime === "" && endTimeError && <p className="error">{endTimeError}</p>}
              </div>)}


              {/* <div className="notify-cont flexc">
                <label className="label-container">
                  <input
                    type="checkbox"
                    defaultChecked={newEvent.notify}
                    name="notify"
                    onChange={handleChange}
                    style={{ height: "40px" }}
                  />
                  <span className="tick-ele" />
                </label>
                <span className="ft-13">
                  Notify Staff and Parents in selected programs
                </span>
              </div> */}

              <div className="notify-cont flexc notify-check-box">
                <input type="checkbox" defaultChecked={newEvent.notify} id="notify" name="notify" onChange={handleChange} />
                <label style={{ margin: "auto" }}> Notify Staff and Parents in selected programs</label>
              </div>

              <div className="field-label full-field error_mes_cus">
                <label>Description</label>
                <div className="field-value-holder margin_top10p">
                  <textarea
                    name="description"
                    value={newEvent.description}
                    // type="text"
                    onChange={handleChange}
                    className="sp-field-input fixed-height-textarea"
                    placeholder="Description"
                  />
                  {/* <input
                    className={`sp-field-input`}
                    placeholder="Description"
                    type="text"
                    name="description"
                    value={newEvent.description}
                    onChange={handleChange}
                    style={{ height: "40px" }}
                  /> */}
                </div>
                {/* {newEvent.description === "" && descriptionError && <p className="error">{descriptionError}</p>} */}
              </div>
              <div className="flexc mt-4 ">
                <div className="flexc food_sleep_saveic calender_cre_can">
                  <button
                    className="createN ft-wt500 mr-2"
                    onClick={handleAddEvent}
                  >
                    {addUpdateStatus === "add" ? "Create" : "Update"}
                  </button>
                  <button
                    className="sp-view-btn ft-wt500 flexc cancel-btn"
                    onClick={() => {
                      setShowAddEventPopup(false);
                      handleFormEmpty();
                      setSelectedDate(null);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Recycle Bin Modal */}
        {
          isRecycleBinVisible && (
            <div
              id="recycle-popup"
              tabIndex={-1}
              className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
            >
              <div className="relative w-full max-w-xs max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4"><div className="p-4 md:p-5 text-center">
                  <div className="round-btn rounded inline-flex alignC justify-center">
                    <img src={de} alt="" />
                  </div>
                  <p className="ft-wt600 ft-16 mb-4 mt-3">Recycle Bin</p>
                  <h3 className="mb-5 font-normal text-sm">
                    Are you sure you want to move this event to Recycle Bin?
                  </h3>
                  <button
                    onClick={() => handleDeleteEvent()}
                    type="button"
                    className="yes_button_sta_cus sp-red-color text-white  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md  inline-flex items-center px-5 py-2.5 text-center"
                  >
                    Yes, Move
                  </button>
                  <button
                    onClick={handleRecycleBinClose}
                    type="button"
                    style={{
                      border: "1px solid #D7D9DD",
                    }}
                    className="can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                  >
                    Cancel
                  </button>

                </div>
                </div>
              </div>
            </div>
          )
        }
        {/* 08-10-24 start  */}
        {showEventPopup && (
          <div
            className="dialog-model absolute edit_cus_style"
            style={{ display: "block" }}
          >
            <div className="flexc justifySp">
              <div className="sp-tt ft-14 ft-wt600 head_cus_cal">
                Event Information
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  float: "right",
                  right: "0",
                }}
              >
                {/* 11-09-2024 Start*/}
                {new Date(newEvent.start).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) && (
                  <>
                    <span
                      className="selected-icon"
                      style={{ border: "unset", cursor: "pointer" }}
                      onClick={() => {
                        setShowEventPopup(false);
                        setShowAddEventPopup(true);
                      }}
                    >
                      <img src={imgEdit} alt="avatar" />
                    </span>
                    <span
                      className="selected-icon"
                      style={{ border: "unset", cursor: "pointer" }}
                      onClick={() => {
                        setShowEventPopup(false);
                        handleRecycleBinClick(newEvent.id);
                      }}
                    >
                      <img src={imgDelete} alt="avatar" />
                    </span>
                  </>
                )}
                {/* 11-09-2024 end*/}
                {/* // 12-09-2024 start */}
                <span
                  className="selected-icon"
                  style={{ border: "unset", cursor: "pointer" }}
                  onClick={() => {
                    setShowEventPopup(false);
                  }}
                >
                  <img
                    src={imgClose}
                    // style={{ height: "45px", width: "45px", paddingTop: "2px" }}
                    alt="avatar"
                  />
                  {/* <i className="icon-Timetable-Outline" /> */}
                </span>
                {/* // 12-09-2024 end */}
              </div>
            </div>
            <div className="form-section-default flexc justifySp fwrap para_cus_sty ">
              <div className="field-label full-field">
                <label>Event</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty event-field">
                  <p>{newEvent.title}</p>
                </div>
              </div>
              <div className="field-label half-field">
                <label>Category</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">
                  {
                    categoryData.find((data) => data.id === category)
                      ?.event_category_name
                  }
                </div>
              </div>
              <div className="field-label half-field">
                <label>Programs</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">
                  {/* {" "}
                  <p>
                    {" "} */}
                  {newEvent.program === 0
                    ? "All Programs"
                    : programsData.find(
                      (data) => data.id === newEvent.program
                    )?.program_name}
                  {/* </p>{" "} */}
                </div>
              </div>
              <div className="field-label half-field">
                <label>Start Date</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">
                  {/* 26.08.2024 */}
                  {formatDate(newEvent.start)}
                </div>
              </div>
              <div className="field-label half-field">
                <label>Start Time</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">
                  {
                    foodTimeData.find((data) => data.id === newEvent.startTime)
                      ?.time_name
                  }
                </div>
              </div>
              <div className="field-label half-field">
                <label>End Date</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">
                  {/* 26.08.2024 */}
                  {formatDate(newEvent.end)}
                </div>
              </div>
              <div className="field-label half-field">
                <label>End Time</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">
                  {
                    foodTimeData.find((data) => data.id === newEvent.endTime)
                      ?.time_name
                  }
                </div>
              </div>
              <div className="field-label full-field">
                <label>Description</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">
                  {newEvent.description}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* 08-10-24 end  */}
        {/* Create New Program */}
        <ProgramAddComponent
          newProgramPopupVisible={newProgramPopupVisible}
          setNewProgramPopupVisible={setNewProgramPopupVisible}
          teachersData={teachersData}
          setProgramsData={setProgramsData}
          newProgramAddUpdateStatus={newProgramAddUpdateStatus}
          setNewProgramAddUpdateStatus={setNewProgramAddUpdateStatus}
        />
      </div>
    </section >
  );
}


