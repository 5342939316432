import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../common/common";
import CommonSidebarFooter from '../../common/layouts/CommonSidebarFooter.jsx';
import Profile from "./Profile.jsx";
import Schoolprofile from "./Schoolprofile.jsx";
import { WindmillSpinner } from 'react-spinner-overlay';
import { Tabs } from "flowbite-react";
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";
import ProgramAddComponent from "../programs/AddProgram.jsx";

export default function Setting() {


    const navigate = useNavigate();

    const [programsLoading, setProgramsLoading] = useState(false);
    const [programsData, setProgramsData] = useState({});
    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem("schoolId"),
                // school_id:24
            };
            const { data } = await axios.post(
                baseUrl + "/api/get_programs",
                postData,
                {
                    headers: {
                        "Authorization": localStorage.getItem('access_token')
                    }
                }
            );
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error);
        }
    };

    const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
    const [newProgramAddUpdateStatus, setNewProgramAddUpdateStatus] = useState(null);
    const handleOpenNewProgramPopupVisible = (status) => {
        setNewProgramAddUpdateStatus(status);
    }
    const [teachersData, setTeachersData] = useState([]);
    const fetchTeachersData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/teacher_list",
            data: {
                school_id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTeachersData(response.data.data);
                console.log(response);
            }
        });
    }

    useEffect(() => {
        fetchProgramsData();
        fetchTeachersData();
    }, []);
    
    return (
        <section className="setting_page">
            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                <CommonSidebarHeader/>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="name_tag_side">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                        <i className="icon-Dashboard" />
                                            <p>Dashboard</p>
                                        </span>
                                    </li>
                                    <li
                                        onClick={() => {
                                            navigate("/activity");
                                        }}
                                    >
                                        <span>
                                        <i className="icon-Activity" />
                                            <p>Activity</p>
                                        </span>
                                    </li>
                                    <li
                                        onClick={() => {
                                            navigate("/calendar");
                                        }}
                                    >
                                        <span>
                                        <i className="icon-Timetable" />
                                            <p>Calendar</p>
                                        </span>
                                    </li>
                                    <li onClick={() => {
                                        navigate("/staff_list");
                                    }}>
                                        <span>
                                        <i className="icon-Staff" />
                                            <p>Staff</p>
                                        </span>
                                    </li>
                                </ul>
                                <h4 className="sp-title flexc justifySp"  onClick={() => handleOpenNewProgramPopupVisible("add")}>
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading &&
                                        programsData &&
                                        programsData.length > 0 ? (
                                        programsData.map((data) => {
                                            return (
                                                <li onClick={() => {
                                                    localStorage.setItem("programId", data.id);
                                                    localStorage.setItem("programName", data.program_name);
                                                    navigate('/student_list');
                                                }}>
                                                    <span>
                                                    <i className="icon-Program_1" />
                                                        <p>{data.program_name}</p>
                                                    </span>
                                                </li>
                                            );
                                        })
                                    ) : (
                                        <div className="data-loader extracss_load">
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <CommonSidebarFooter />
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp top_bar_cus back-white">
                        <div className="ft-wt600 flexc">
                            <p className="program_tit fs-15">Settings</p>
                        </div>
                    </div>
                    <div className="tab_pro_sett">
                        <Tabs aria-label="Tabs with underline" variant="underline">
                            <Tabs.Item active title="My Profile">
                                <div className="tab_inside_content">
                                    <Profile />
                                </div>
                            </Tabs.Item>
                            <Tabs.Item title="School Profile">
                                <Schoolprofile />
                            </Tabs.Item>
                            {/* <Tabs.Item title="School Preferences">
                                This is <span className="font-medium text-gray-800 dark:text-white">Settings tab's associated content</span>.
                                Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to
                                control the content visibility and styling.
                            </Tabs.Item> */}
                        </Tabs>
                    </div>
                </div>
            </div>
             {/* Create New Program */}
            <ProgramAddComponent
                newProgramPopupVisible={newProgramPopupVisible}
                setNewProgramPopupVisible={setNewProgramPopupVisible}
                teachersData={teachersData}
                setProgramsData={setProgramsData}
                newProgramAddUpdateStatus={newProgramAddUpdateStatus}
                setNewProgramAddUpdateStatus={setNewProgramAddUpdateStatus}
            />
        </section>
    );
}
