import React, { useState, useEffect } from "react";
import axios from 'axios';
import Lock from "../../common/components/images/Lock.svg";
import mail from "../../common/components/images/mail.svg";
import phonedail from "../../common/components/images/phone.svg";
import ava from "../../common/components/images/ava.svg";
import { Avatar } from "flowbite-react";
import '../../common/components/js/common.js';
import { baseUrl } from '../../common/common';
import validator from "validator";
import { Card } from "flowbite-react";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';

function Profile() {

    const [staffId, setStaffId] = useState("");
    const [schoolId, setSchoolId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [profileImage, setProfileImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setProfileImage(file);
    };

    const [validationErrors, setValidationErrors] = useState({
        name: "",
        email: "",
        phone: "",
        profileImage: ""
    });

    const personalInfoValidation = () => {
        let errors = {};
        if (name === "") {
            errors.name = "Staff name cannot be empty";
        }
        if (email === "") {
            errors.email = "Staff email cannot be empty";
        } else if (!validator.isEmail(email)) {
            errors.email = "Please enter valid email";
        }
        if (phone.trim() === "") {
            errors.phone = "Staff Phone number cannot be empty";
        } else if (
            !validator.isMobilePhone(phone.trim()) ||
            phone.trim().length !== 10
        ) {
            errors.phone = "Please enter valid mobile number";
        }
        if ((profileDetails.profile_image === null && profileDetails.profile_image === "") && (profileImage === null)) {
            errors.profileImage = "Staff logo cannot be empty";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [passValidationErrors, setPassValidationErrors] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });

    const passwordValidation = () => {
        let errors = {};
        if (currentPassword === "") {
            errors.currentPassword = "Current Password cannot be empty";
        }
        if (newPassword.trim() === "") {
            errors.newPassword = "New Password cannot be empty";
        }
        if (confirmPassword.trim() === "") {
            errors.confirmPassword = "Confirm Password cannot be empty";
        } else if (
            newPassword.trim() !== confirmPassword.trim()
        ) {
            errors.confirmPassword = "New and Confirm Passwords do not match";
        }
        setPassValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const updatePersonalInfo = () => {
        if (personalInfoValidation()) {
            axios({
                method: "post",
                url: baseUrl + "/api/my_profile_update",
                data: {
                    id: staffId,
                    school_id: schoolId,
                    first_name: name !== null ? name : "",
                    email: email !== null ? email : "",
                    phone: phone !== null ? phone : "",
                    updated_by: localStorage.getItem('loginUserId'),
                    profile_image: profileImage !== null ? profileImage : "",
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                if (response.data.status === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    handleShowPersonalInfoEdit(false);
                    getProfileDetails();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }
    }

    const updatePassword = () => {
        if (passwordValidation()) {
            axios({
                method: "post",
                url: baseUrl + "/api/update_password",
                data: {
                    id: staffId,
                    password: confirmPassword,
                    old_password: currentPassword
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                if (response.data.status === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    setPasswordEdit(false);
                    getProfileDetails();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }
    }

    const [personalInfoEdit, setPersonalInfoEdit] = useState(false);
    const [passwordEdit, setPasswordEdit] = useState(false);
    const [profileDetails, setProfileDetails] = useState(null);
    const getProfileDetails = () => {
        try {
            axios({
                method: 'post',
                url: baseUrl + '/api/get_admin_profile',
                data: {
                    id: localStorage.getItem('schoolId'),
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: localStorage.getItem('access_token'),
                },
            }).then(response => {
                console.log(response);
                if (response.data.status === true) {
                    setStaffId(response.data.data[0].id);
                    setSchoolId(response.data.data[0].school_id);
                    setName(response.data.data[0].first_name);
                    setEmail(response.data.data[0].email);
                    setPhone(response.data.data[0].phone);
                    setProfileDetails(response.data.data[0]);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    const handleShowPersonalInfoEdit = (status) => {
        setProfileImage(null);
        setValidationErrors({
            name: "",
            email: "",
            phone: "",
            profileImage: ""
        });
        setName(profileDetails.first_name);
        setEmail(profileDetails.email);
        setPhone(profileDetails.phone);
        setPersonalInfoEdit(status);
    }
    const handleShowPasswordEdit = (status) => {
        setPassValidationErrors({
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        });
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setPasswordEdit(status);
    }
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    useEffect(() => {
        getProfileDetails();
    }, []);

    return (
        <section className="profile_page">
            <Snackbar className={`snack_total ${messageType === 'success' ?"snackbar_success":"snackbar_failure"}`}
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                    <div>
                        {messageType === 'success' ?
                        <span class="icon-List_Present" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>:                    
                        <span class="icon-List_Absent" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                            <span class="path6"></span>
                        </span>
                        }
                    </div>
                            {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                // ContentProps={{
                //     sx: {
                //         border: "1px solid",
                //         opacity: "1",
                //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         width: 'auto',
                //         minWidth:'auto'
                //     }
                // }}
                autoHideDuration={3000}
            />
            <div className="m-25">
                {
                    personalInfoEdit === false ?
                        profileDetails !== null && <Card className="max-w-sm">
                            <div className="per_info_pro">
                                <div>
                                    <h5>Personal Information</h5>
                                </div>
                                <div className="dip_fle">
                                    <button class="profile_cancel" onClick={() => handleShowPersonalInfoEdit(true)}><i className="icon-Student_Edit" />&nbsp;<span>Edit</span></button>
                                </div>
                            </div>
                            <div className="pro_pss_edit">
                                <div>
                                    <Avatar img={(profileDetails.profile_image !== null && profileDetails.profile_image !== "") ? baseUrl + '/api/' + profileDetails.profile_image : ava} />
                                </div>
                                <div className="flex_pass_start text-cap">
                                    <h4>{profileDetails.first_name}</h4>
                                    <p>School Leader</p>
                                </div>
                            </div>
                            <div className="info_details_profi">
                                <div className="pro_pss_lock">
                                    <div>
                                        {/* <img src={} /> */}
                                        <Avatar img={mail} bordered />
                                    </div>
                                    <div className="flex_pass_start">
                                        <h4>Email</h4>
                                        <p>{profileDetails.email}</p>
                                    </div>
                                </div>
                                <div className="pro_pss_lock">
                                    <div>
                                        <Avatar img={phonedail} bordered />
                                    </div>
                                    <div className="flex_pass_start">
                                        <h4>Phone Number</h4>
                                        <p>{profileDetails.phone}</p>
                                    </div>
                                </div>
                            </div>
                        </Card> :
                        <Card className="max-w-sm">
                            <div className="per_info_pro">
                                <div>
                                    <h5>Personal Information</h5>
                                </div>
                                <div className="dip_fle">
                                    <button class="profile_cancel" onClick={() => handleShowPersonalInfoEdit(false)}><span>Cancel</span></button>
                                    <button class="profile_save" onClick={() => updatePersonalInfo()}><span>Save</span></button>
                                </div>
                            </div>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Staff Photo</label><span className="col_red">*</span>
                                    <div className="field-value-holder" style={{ borderColor: validationErrors.profileImage ? 'red' : '' }}>
                                        <span className="sp-upload-btn flexc justifySp" >
                                            <p className="truncate" style={{
                                                color: "#373943",
                                                fontWeight: "600",
                                                fontSize: "13px",
                                                fontFamily: "Inter",
                                            }}> {((profileDetails.profile_image === null && profileDetails.profile_image === "") && (profileImage === null)) ? "My profile.png" : profileImage === null ? profileDetails.profile_image : profileImage.name}
                                            </p>
                                            <button className="sp-file-browse">Browse</button>
                                        </span>
                                        <input
                                            className="input-placeholder1"
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    {validationErrors.profileImage && (
                                        <p className="error pos_abo_stu">{validationErrors.profileImage}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Staff Name <span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            type="text"
                                            className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                            placeholder="Enter full name"
                                            value={name}
                                            onChange={(ev) =>
                                                setName(ev.target.value)
                                            }
                                            style={{ borderColor: validationErrors.name ? 'red' : '' }}
                                        />
                                    </div>
                                    {validationErrors.name && (
                                        <p className="error pos_abo_stu">{validationErrors.name}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Email  <span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            type="text"
                                            className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                            placeholder="sudharsonhariharan@vidyala.com"
                                            value={email}
                                            onChange={(ev) =>
                                                setEmail(ev.target.value)
                                            }
                                            style={{ borderColor: validationErrors.email ? 'red' : '' }}
                                        />
                                    </div>
                                    {validationErrors.email && (
                                        <p className="error pos_abo_stu">{validationErrors.email}</p>
                                    )}

                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Phone Number <span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            type="text"
                                            className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                            placeholder="+91 8845998843"
                                            value={phone}
                                            onChange={(ev) =>
                                                setPhone(ev.target.value)
                                            }
                                            style={{ borderColor: validationErrors.phone ? 'red' : '' }}
                                        />
                                    </div>
                                    {validationErrors.phone && (
                                        <p className="error pos_abo_stu">{validationErrors.phone}</p>
                                    )}
                                </div>
                            </div>
                        </Card>
                }

            </div>
            <div className="m-25">
                {
                    passwordEdit === false ?
                        profileDetails !== null && <Card className="max-w-sm">
                            <div className="per_info_pro">
                                <div>
                                    <h5>Password</h5>
                                </div>
                                <div className="dip_fle">
                                    <button class="profile_cancel" onClick={() => handleShowPasswordEdit(true)}><i className="icon-Student_Edit" />&nbsp;<span>Edit</span></button>
                                </div>
                            </div>
                            <div className="pro_pss_lock">
                                <div>
                                    {/* <img src={Lock} /> */}
                                    <Avatar img={Lock} bordered />
                                </div>
                                <div className="flex_pass_start">
                                    <h4>Current Password</h4>
                                    <p>**********************</p>
                                </div>
                            </div>

                        </Card> :
                        <Card className="max-w-sm">
                            <div className="per_info_pro">
                                <div>
                                    <h5>Password</h5>
                                </div>
                                <div className="dip_fle">
                                    <button class="profile_cancel" onClick={() => handleShowPasswordEdit(false)}><span>Cancel</span></button>
                                    <button class="profile_save" onClick={() => updatePassword()}><span>Save</span></button>
                                </div>
                            </div>
                            <div className="form-section-default flexc justifySp fwrap">

                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Current Password  <span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            type="password"
                                            className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                            placeholder="***************"
                                            value={currentPassword}
                                            onChange={(ev) =>
                                                setCurrentPassword(ev.target.value)
                                            }
                                            style={{ borderColor: passValidationErrors.currentPassword ? 'red' : '' }}
                                        />
                                    </div>
                                    {passValidationErrors.currentPassword && (
                                        <p className="error pos_abo_stu">{passValidationErrors.currentPassword}</p>
                                    )}

                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>New Password  <span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            type="password"
                                            className={`custom-password-field field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}

                                            placeholder="***************"
                                            value={newPassword}
                                            onChange={(ev) =>
                                                setNewPassword(ev.target.value)
                                            }
                                            style={{ borderColor: passValidationErrors.newPassword ? 'red' : '' }}
                                        />
                                    </div>
                                    {passValidationErrors.newPassword && (
                                        <p className="error pos_abo_stu">{passValidationErrors.newPassword}</p>
                                    )}

                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Confirm Password <span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            type="password"
                                            className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                            placeholder="***************"
                                            value={confirmPassword}
                                            onChange={(ev) =>
                                                setConfirmPassword(ev.target.value)
                                            }
                                            style={{ borderColor: passValidationErrors.confirmPassword ? 'red' : '' }}
                                        />
                                    </div>
                                    {passValidationErrors.confirmPassword && (
                                        <p className="error pos_abo_stu">{passValidationErrors.confirmPassword}</p>
                                    )}

                                </div>
                            </div>
                        </Card>
                }
            </div>
        </section>
    );
}

export default Profile;