import axios from 'axios';
import React, { useState, useEffect } from "react";
import { baseUrl } from '../../common/common';
import { Avatar } from "flowbite-react";

export default function CommonSidebarHeader(){

    const [schoolData, setSchoolData] = useState({});
    const fetchSchoolDetails = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_school_profile",
            data: {
                id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setSchoolData(response.data.data[0]);
                console.log(response);
            }
        });
    }
    
    useEffect(() => {
        fetchSchoolDetails();
    }, []);
    return (
        <section>
          <div className="sp-top-band">
            {
                schoolData&&           
                <div className="logo_top_display">
                {(schoolData.school_logo!==null&&schoolData.school_logo!=="")&&<Avatar img={baseUrl + '/api/' + schoolData.school_logo} />}
                <a className="sp-logo">
                    {schoolData.school_name}<sup>Beta</sup>
                </a>
                </div>
             }
          </div>
        </section> 
    );
}