import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../common/components/css/style1.css";
import '../../common/components/js/school_info.js';
import axios from "axios";
import $ from "jquery";
import { baseUrl } from "../../common/common.jsx";
import logo from "../../pages/signup/images/logo.svg";
import validator from "validator";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';

export default function School_info() {
    const navigate = useNavigate();
    const location = useLocation();

    const [schoolName, setschoolName] = useState("");
    const [schoolPhone, setschoolPhone] = useState("");
    const [schoolEmail, setschoolEmail] = useState("");
    const [schoolWebsiteUrl, setschoolWebsiteUrl] = useState("");
    const [schoolAddress, setschoolAddress] = useState("");
    const [schoolSize, setschoolSize] = useState("");
    const [schoolLogo, setSchoolLogo] = useState(null);



    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSchoolLogo(file);
    };

    const [schoolProgram, setschoolProgram] = useState([]);
    const handlePermissionChange = (permissionId) => {       
        const newSelection = schoolProgram.includes(permissionId)
            ? schoolProgram.filter(id => id !== permissionId)
            : [...schoolProgram, permissionId];
        setschoolProgram(newSelection);
        setRefreshPrograms(false);
        setTimeout(function () {
            setRefreshPrograms(true);
        }, 0);
        setNextButtonDisableStatus(false);
    };

    const userData = {
        "full_name": location.state.name,
        "email": location.state.email,
        "phone": location.state.phone,
        "password": location.state.password,
        "agree": location.state.agree,
        "size_id": schoolSize,
        "otp_verify": 1,
        "otp": location.state.otp,
        "status": 1,
        "created_by": 1,
        "updated_by": 1,
        "school_name": schoolName,
        "programs": schoolProgram,
        "id": location.state.dbInsertId,
        "school_phone": schoolPhone,
        "school_email": schoolEmail,
        "website_url": schoolWebsiteUrl,
        "school_address": schoolAddress,
        "school_logo": schoolLogo
    };


    const saveUserData = () => {
        if (selectedIndex === 0) {
            if (schoolInfoValidation()) {
                if(schoolSize===""){
                    setNextButtonDisableStatus(true);
                }else{
                    setNextButtonDisableStatus(false);   
                }
                setSelectedIndex(1);
                $('.progress-bar').find('.progress-bar-fill').css('width', 40 + "%");
            }
            
        } else if (selectedIndex === 1) {
            if(schoolProgram.length===0){
                setNextButtonDisableStatus(true);
               }else{
                setNextButtonDisableStatus(false);   
               }
            setSelectedIndex(2);
            $('.progress-bar').find('.progress-bar-fill').css('width', 60 + "%");
        } else if (selectedIndex === 2) {
            if (schoolProgram.length === 0) {
                setSnackbarMessage("Please Select programs");
                setMessageType('error');
                handleSnackbarOpen();
            } else {
                axios({
                    method: "post",
                    url: baseUrl + "/api/signup",
                    data: userData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then((response) => {
                    if (response['data']['status'] === true) {
                        $('.progress-bar').find('.progress-bar-fill').css('width', 100 + "%");
                        navigate('/student_list');
                        window.location.reload();
                        localStorage.setItem('schoolId', response['data']['data'][0]['school_id']);
                        localStorage.setItem('loginUserId', response['data']['data'][0]['id']);
                        localStorage.setItem('loginUserName', response['data']['data'][0]['first_name']);
                        localStorage.setItem('roleId', response['data']['data'][0]['role_id']);
                        localStorage.setItem('access_token', response['data']['access_token']);
                        console.log(response);
                    }
                });
            }
        }
    }

    const handleSchoolSize = (id) => {
        setschoolSize(id);
        setNextButtonDisableStatus(false);
    };
    const backButtonAction = () => {
        if (selectedIndex === 1) {
            setNextButtonDisableStatus(false);
            setSelectedIndex(0);
            $('.progress-bar').find('.progress-bar-fill').css('width', 20 + "%");
        } else if (selectedIndex === 2) {
            if(schoolSize===""){
             setNextButtonDisableStatus(true);
            }else{
             setNextButtonDisableStatus(false);   
            }
            setSelectedIndex(1);
            $('.progress-bar').find('.progress-bar-fill').css('width', 40 + "%");
        }
    }
    const [schoolSizeData, setSchoolSizeData] = useState({});
    const [schoolSizeLoading, setSchoolSizeLoading] = useState(false);

    const fetchSchoolSizeData = async () => {
        try {
            const { data } = await axios.post(baseUrl + "/api/get_size_master");
            setSchoolSizeData(data.data);
            setSchoolSizeLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [schoolProgramsData, setSchoolProgramsData] = useState({});
    const [schoolProgramsLoading, setSchoolProgramsLoading] = useState(false);

    const fetchSchoolProgramsData = async () => {
        try {
            const { data } = await axios.post(baseUrl + "/api/get_programs_master");
            setSchoolProgramsData(data.data);
            setSchoolProgramsLoading(true)
        } catch (error) {
            console.log(error)
        }
    }

    const [nextButtonDisableStatus, setNextButtonDisableStatus] = useState(false);
    // const schoolInfoValidation=()=>{
    //     return (schoolName!==""&&schoolPhone!==""&&schoolEmail!==""&&schoolWebsiteUrl!==""&&schoolAddress!==""&&selectedImage!==null);    
    // };
    const [validationErrors, setValidationErrors] = useState({
        schoolName: "",
        schoolEmail: "",
        schoolPhone: "",
        schoolWebsiteUrl: "",
        schoolAddress: "",
        schoolLogo: ""
    });

    const schoolInfoValidation = () => {
        let errors = {};
        if (schoolName === "") {
            errors.schoolName = "School name cannot be empty";
        }
        if (schoolEmail === "") {
            errors.schoolEmail = "School email cannot be empty";
        } else if (!validator.isEmail(schoolEmail)) {
            errors.schoolEmail = "Please enter valid email";
        }
        if (schoolPhone.trim() === "") {
            errors.schoolPhone = "School mobile number cannot be empty";
        } else if (
            !validator.isMobilePhone(schoolPhone.trim()) ||
            schoolPhone.trim().length !== 10
        ) {
            errors.schoolPhone = "Please enter valid mobile number";
        }
        // if (schoolWebsiteUrl === "") {
        //     errors.schoolWebsiteUrl = "School website cannot be empty";
        // }
        if (schoolAddress === "") {
            errors.schoolAddress = "School address cannot be empty";
        }
        if (schoolLogo === null) {
            errors.schoolLogo = "School logo cannot be empty";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [selectedIndex, setSelectedIndex] = useState(0);

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    const [refreshLogo, setRefreshLogo] = useState(true);
    const handleRemoveLogo = () => {
        setSchoolLogo(null);
        setRefreshLogo(false);
        setTimeout(function () {
            setRefreshLogo(true);
        }, 50);
    };

    const [refreshPrograms, setRefreshPrograms] = useState(true);

    useEffect(() => {
        fetchSchoolSizeData();
        // $(document).ready(function () {
        // var current = $('.box-container>div.sp-sec-active');
        // current.addClass('completed').removeClass('sp-sec-active').next().addClass('sp-sec-active');
        // if ($('.box-container>div.sp-sec-active')) {
        //     $('.next-btn').attr('disabled', false);
        // } 
        // else {
        //     $('.next-btn').attr('disabled', false);
        // }
        // $(document).on('click', '.next-btn', function () {
        //     if (schoolInfoValidation()) {
        //         var current = $('.box-container>div.sp-sec-active');
        //         current.addClass('completed').removeClass('sp-sec-active').next().addClass('sp-sec-active');
        //         if ($('.box-container>div.sp-sec-active').hasClass('completed')) {
        //             $('.next-btn').attr('disabled', false);
        //         } else {
        //             $('.next-btn').attr('disabled', true);
        //         }
        //         updateProgressBar();
        //     }
        // });
        // if (schoolInfoValidation()) {
        // $('.next-btn').attr('disabled', false);
        // }
        // });
        // $(document).ready(function () {
        //     $(".search-sch-list").keypress(function (event) {
        //         $('.next-btn').attr('disabled', false);
        //         var value = $(this).val().toLowerCase();
        //         $('#schoolList').addClass('active');
        //         $("#schoolList div").filter(function () {
        //             $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        //         });
        //         $('.close-search').removeClass('active');
        //         $(".sch-text-op").empty();
        //     });
        // });
        fetchSchoolProgramsData();
    }, []);



    return (
        <section>
            <Snackbar className={`snack_total ${messageType === 'success' ?"snackbar_success":"snackbar_failure"}`}
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                    <div>
                        {messageType === 'success' ?
                        <span class="icon-List_Present" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>:                    
                        <span class="icon-List_Absent" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                            <span class="path6"></span>
                        </span>
                        }
                    </div>
                            {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                // ContentProps={{
                //     sx: {
                //         border: "1px solid",
                //         opacity: "1",
                //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         width: 'auto',
                //         minWidth:'auto'
                //     }
                // }}
                autoHideDuration={3000}
            />
            <div className="log-address-inr flex-prop">
                <div className="log-address-cont">
                    {/* <div className="log-address-top flex-prop">
                        <div className="address-top1 flex-prop">
                            <img src={panda_image} style={{ width: "30px" }} width="30" height="35" alt="panda" />
                            <h4 className="scl-name">Scool Panda</h4>
                        </div>
                        <p>Welcome, {location.state.name}!</p>
                    </div> */}
                    <div className="box-container total_error dis_on_board_cener">
                        {
                            selectedIndex === 0 && <div className="sp-sec-active">
                                <div className="onbording_main padt_35">
                                    <h3>Let’s Start with the basics</h3>
                                    <p>Your Logo<span className="col_red">*</span></p>
                                    <div className="total_div_change pos_stu_cus">
                                        <div>
                                            <div className={`${schoolLogo === null ? "logo_sec" : "logo_sec_after"}`}>
                                                <img src={schoolLogo !== null ? URL.createObjectURL(schoolLogo) : logo} />
                                            </div>
                                        </div>
                                        <div className="totl_inside_one">
                                            <div className="totl_inside_two">
                                                {/* <a href=""> */}
                                                {
                                                    refreshLogo && <p className="change_logo_onbor" style={{ position: 'relative' }}>
                                                        <input
                                                            type="file"
                                                            id="imageInput"
                                                            style={{ width: '100%', height: "revert-layer", margin: '0px' }}
                                                            accept=".jpg, .jpeg, .png, .gif"
                                                            // multiple
                                                            onChange={handleImageChange}
                                                        />
                                                        Change Logo
                                                    </p>
                                                }
                                                {/* </a> */}
                                                <p>or</p>
                                                <p className="remove_logo_onbor" style={{ cursor: "pointer" }} onClick={() => handleRemoveLogo()}>Remove Logo</p>
                                            </div>
                                            <div>
                                                <p>We recommend a resolution of at least 32x32 and 1:1 aspect ratio</p>
                                            </div>
                                            {validationErrors.schoolLogo && (
                                                <p className="error pos_abo_stu logo_error_mes">{validationErrors.schoolLogo}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="onbor_div_input pt-25" >
                                        <div className="form-section-default flexc justifySp fwrap padbl_30">
                                            <div className="field-label half-field error_mes_cus pos_stu_cus" >
                                                <label>School Name
                                                    <span className="col_red">*</span>
                                                </label>
                                                <div className="">
                                                    <input type="text"
                                                        className={`field-value-holder extra_bor_clr sp-field-input`}
                                                        placeholder="Enter school name"
                                                        value={schoolName}
                                                        onChange={(ev) =>
                                                            setschoolName(ev.target.value)
                                                        }
                                                        style={{ borderColor: validationErrors.schoolName ? 'red' : '' }}
                                                    />
                                                </div>
                                                {validationErrors.schoolName && (
                                                    <p className="error pos_abo_stu">{validationErrors.schoolName}</p>
                                                )}
                                                {/* <p className="error pos_abo_stu"></p> */}
                                            </div>
                                            <div className="field-label half-field error_mes_cus pos_stu_cus" >
                                                <label>Email ID
                                                    <span className="col_red">*</span>
                                                </label>
                                                <div className="">
                                                    <input type="text"
                                                        className={`field-value-holder extra_bor_clr sp-field-input`}
                                                        placeholder="Enter Email ID"
                                                        value={schoolEmail}
                                                        onChange={(ev) =>
                                                            setschoolEmail(ev.target.value)
                                                        }
                                                        style={{ borderColor: validationErrors.schoolEmail ? 'red' : '' }}
                                                    />
                                                </div>
                                                {validationErrors.schoolEmail && (
                                                    <p className="error pos_abo_stu">{validationErrors.schoolEmail}</p>
                                                )}
                                                {/* <p className="error pos_abo_stu"></p> */}
                                            </div>
                                            <div className="field-label half-field error_mes_cus pos_stu_cus" >
                                                <label>Phone Number
                                                    <span className="col_red">*</span>
                                                </label>
                                                <div className="">
                                                    <input type="text"
                                                        className={`field-value-holder extra_bor_clr sp-field-input`}
                                                        placeholder="+91"
                                                        value={schoolPhone}
                                                        onChange={(ev) =>
                                                            setschoolPhone(ev.target.value)
                                                        }
                                                        style={{ borderColor: validationErrors.schoolPhone ? 'red' : '' }}
                                                    />
                                                </div>
                                                {validationErrors.schoolPhone && (
                                                    <p className="error pos_abo_stu">{validationErrors.schoolPhone}</p>
                                                )}
                                                {/* <p className="error pos_abo_stu"></p> */}
                                            </div>
                                            <div className="field-label half-field error_mes_cus pos_stu_cus" >
                                                <label>Website
                                                    {/* <span className="col_red">*</span> */}
                                                </label>
                                                <div className="">
                                                    <input type="text"
                                                        className={`field-value-holder extra_bor_clr sp-field-input`}
                                                        placeholder="https:/"
                                                        value={schoolWebsiteUrl}
                                                        onChange={(ev) =>
                                                            setschoolWebsiteUrl(ev.target.value)
                                                        }
                                                    />
                                                </div>
                                                {validationErrors.schoolWebsiteUrl && (
                                                    <p className="error pos_abo_stu">{validationErrors.schoolWebsiteUrl}</p>
                                                )}
                                                {/* <p className="error pos_abo_stu"></p> */}
                                            </div>
                                            <div className="field-label full-field error_mes_cus pos_stu_cus" >
                                                <label>School Address
                                                    <span className="col_red">*</span>
                                                </label>
                                                <div className="">
                                                    <input type="text"
                                                        className={`field-value-holder extra_bor_clr sp-field-input`}
                                                        value={schoolAddress}
                                                        onChange={(ev) =>
                                                            setschoolAddress(ev.target.value)
                                                        }
                                                        style={{ borderColor: validationErrors.schoolAddress ? 'red' : '' }}

                                                    />
                                                </div>
                                                {validationErrors.schoolAddress && (
                                                    <p className="error pos_abo_stu">{validationErrors.schoolAddress}</p>
                                                )}
                                                {/* <p className="error pos_abo_stu"></p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* <div className="sp-search-school sp-sec-active">
                            <h3>What’s your School Name?</h3>
                            <div className="pos">
                                <input
                                    type="text"
                                    placeholder="Type to search your school.."
                                    className="search-sch-list"
                                    onChange={(e) => setschoolName(e.target.value)}
                                />
                            </div>
                        </div> */}
                        {
                            selectedIndex === 1 &&
                            <div className="scl-size-cont school_size_cus sp-sec-active">
                                <h3>How many Toddler in your School?</h3>
                                {/* <h3>What’s your School Size?</h3> */}
                                {(schoolSizeLoading && schoolSizeData && schoolSizeData.length > 0) ? (
                                    <div className="size-cont1 flex-prop custom_size_info"  >
                                        {schoolSizeData.slice(0, 4).map((data) => (
                                            <button key={data.id} onClick={() => handleSchoolSize(data.id)} className={`${schoolSize===data.id&&"sp-selected"}`}>
                                                <div className="school_dis_p">
                                                    <label className="label-container">
                                                        <input id={data.id} type="radio" name="radio" checked={schoolSize===data.id} value={schoolSize} onChange={(e) => handleSchoolSize(data.id)} />
                                                        <span className="circle-ele" />
                                                    </label>
                                                    <p className="scool_size_par">{data.school_size}</p></div>
                                            </button>
                                        ))}
                                    </div>
                                ) : (
                                    <div>Loading....</div>
                                )}

                                {(schoolSizeLoading && schoolSizeData && schoolSizeData.length > 0) ? (
                                    <div className="size-cont1 flex-prop">
                                        {schoolSizeData.slice(4, 9).map((data) => (
                                            <button key={data.id} onClick={() => handleSchoolSize(data.id)} className={`${schoolSize===data.id&&"sp-selected"}`}>
                                                <div className="school_dis_p">
                                                    <label className="label-container">
                                                        <input id={data.id} type="radio" name="radio" checked={schoolSize===data.id} value={schoolSize} onChange={(e) => handleSchoolSize(data.id)} />
                                                        <span className="circle-ele" />
                                                    </label>
                                                    <p className="scool_size_par">{data.school_size}</p></div>
                                            </button>
                                        ))}
                                    </div>
                                ) : (
                                    <div>Loading....</div>
                                )}
                            </div>
                        }
                        {
                            selectedIndex === 2 &&
                            <div className="scl-prg sp-sec-active">
                                <h3 className="program_school_pos">What are the programs you have in your school?</h3>
                                {/* <div className="scl-prg-name">
                                <div className="size-cont1 program-size flex-prop ">
                                    {(schoolProgramsLoading && schoolProgramsData && schoolProgramsData.length > 0) ?
                                        schoolProgramsData.map((data) => (
                                            <button key={data.id} onClick={() => handlePermissionChange(data.id)} className="last_div">
                                                <label className="label-container">
                                                    <input type="checkbox" checked={schoolProgram.includes(data.id)} onChange={() => handlePermissionChange(data.id)} />
                                                    <span className="tick-ele" />
                                                </label>
                                                {data.program_name}
                                            </button>
                                        )) : <div>Loading....</div>}
                                </div>
                            </div> */}
                            <div className="check_box_schoolinfo">

                              {  <div className="scl-prg-name extra_progr_tic">
                                    {(schoolProgramsLoading && schoolProgramsData && schoolProgramsData.length > 0) ? (
                                        <div className="size-cont1 program-size flex-prop custom_program_info">
                                            {schoolProgramsData.slice(0, 4).map((data) => (
                                              refreshPrograms &&  <button key={data.id} onClick={() => handlePermissionChange(data.id)}  className={`last_div ${schoolProgram.includes(data.id) ? "sp-selected" : ""}`}>
                                                    <div className="align_cen_progr">
                                                        <label className="label-container">
                                                            <input id={data.id} type="checkbox" checked={schoolProgram.includes(data.id)} onChange={() => handlePermissionChange(data.id)} />
                                                            <span className="tick-ele" />
                                                        </label>
                                                        <p className="program_name">{data.program_name}</p>
                                                    </div>
                                                </button>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>Loading....</div>
                                    )}

                                    {(schoolProgramsLoading && schoolProgramsData && schoolProgramsData.length > 0) ? (
                                      <div className="size-cont1 program-size flex-prop extra_progr_tic p-o">
                                            {schoolProgramsData.slice(4, 9).map((data) => (
                                                refreshPrograms &&  <button key={data.id} onClick={() => handlePermissionChange(data.id)}  className={`last_div ${schoolProgram.includes(data.id) ? "sp-selected" : ""}`}>
                                                    <div className="align_cen_progr">
                                                        <label className="label-container">
                                                            <input id={`checkbox-item-${data.id}`} type="checkbox" checked={schoolProgram.includes(data.id)} onChange={() => handlePermissionChange(data.id)} />
                                                            <span className="tick-ele" />
                                                        </label>
                                                        <p className="program_name">{data.program_name}</p>
                                                    </div>
                                                </button>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>Loading....</div>
                                    )}
                                </div>
                                }
                                </div>
                            </div>
                        }
                    </div>

                    <div className="login-bottom">
                        <div className="progress-bar">
                            <div className="progress-bar-fill"></div>
                        </div>
                        <div className="sp-progress-btn flex-prop">
                            <button className="back-btn school_info_back_btn" disabled={selectedIndex === 0} onClick={() => backButtonAction()}>Back</button>
                            {/* <button onClick={() => saveUserData()} className="last_button">Next</button> */}
                            <button className="next-btn" disabled={nextButtonDisableStatus} onClick={() => saveUserData()}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
