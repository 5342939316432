import React, { useState, useEffect } from "react";
import axios from 'axios';
import mail from "../../common/components/images/mail.svg";
import call from "../../common/components/images/phone.svg";
import web from "../../common/components/images/web.svg";
import lang from "../../common/components/images/lang.svg";
import time from "../../common/components/images/time.svg";
import loction from "../../common/components/images/loction.svg";
import log from "../../common/components/images/log.svg";
import { Avatar } from "flowbite-react";
import '../../common/components/js/common.js';
import { Dropdown } from "flowbite-react";
import { Card } from "flowbite-react";
import { baseUrl } from '../../common/common';
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import validator from "validator";

function Schoolprofile() {

    const [schoolId, setSchoolId] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [schoolPhone, setSchoolPhone] = useState("");
    const [schoolEmail, setSchoolEmail] = useState("");
    const [schoolWebsiteUrl, setSchoolWebsiteUrl] = useState("");
    const [schoolAddress, setSchoolAddress] = useState("");
    const [schoolLanguage, setSchoolLanguage] = useState("");
    const [schoolTimeZone, setSchoolTimeZone] = useState(1);
    const [schoolLogo, setSchoolLogo] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSchoolLogo(file);
    };

    const [validationErrors, setValidationErrors] = useState({
        schoolName: "",
        schoolEmail: "",
        schoolPhone: "",
        // schoolWebsiteUrl: "",
        schoolAddress: "",
        // schoolLanguage: "",
        // schoolTimeZone: "",
        schoolLogo: ""
    });

    const schoolInfoValidation = () => {
        let errors = {};
        if (schoolName === "" || schoolName === null) {
            errors.schoolName = "School name cannot be empty";
        }
        if (schoolEmail === "" || schoolEmail === null) {
            errors.schoolEmail = "School email cannot be empty";
        } else if (!validator.isEmail(schoolEmail)) {
            errors.schoolEmail = "Please enter valid email";
        }
        if (schoolPhone === "" || schoolPhone === null) {
            errors.schoolPhone = "School mobile number cannot be empty";
        } else if (
            !validator.isMobilePhone(schoolPhone) ||
            schoolPhone.length !== 10
        ) {
            errors.schoolPhone = "Please enter valid mobile number";
        }
        // if (schoolWebsiteUrl === "") {
        //     errors.schoolWebsiteUrl = "School website cannot be empty";
        // }
        if (schoolAddress === "" || schoolAddress === null) {
            errors.schoolAddress = "School address cannot be empty";
        }
        if ((schoolLogo === "" || schoolLogo === null) && (schoolProfileDetails.school_logo === null || schoolProfileDetails.school_logo === "")) {
            errors.schoolLogo = "School logo cannot be empty";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    const [refreshLogo, setRefreshLogo] = useState(true);

    const handleRemoveLogo = () => {
        setSchoolLogo(null);
        setRefreshLogo(false);
        setTimeout(function () {
            setRefreshLogo(true);
        }, 50);
    };

    const [schoolInfoEdit, setSchoolInfoEdit] = useState(false);

    const handleShowSchoolInfoEdit = (status) => {
        setSchoolLogo(null);
        setValidationErrors({
            schoolName: "",
            schoolEmail: "",
            schoolPhone: "",
            // schoolWebsiteUrl: "",
            schoolAddress: "",
            // schoolLanguage: "",
            // schoolTimeZone: "",
            schoolLogo: ""
        });
        setSchoolName(schoolProfileDetails.school_name);
        setSchoolEmail(schoolProfileDetails.school_email);
        setSchoolPhone(schoolProfileDetails.school_phone);
        setSchoolWebsiteUrl(schoolProfileDetails.website_url);
        setSchoolAddress(schoolProfileDetails.school_address);
        setSchoolLanguage(schoolProfileDetails.language);
        setSchoolTimeZone(schoolProfileDetails.time_zone);
        setSchoolInfoEdit(status);
    }

    const updateSchoolInfo = () => {
        if (schoolInfoValidation()) {
            axios({
                method: "post",
                url: baseUrl + "/api/school_profile_update",
                data: {
                    id: schoolId,
                    school_name: schoolName !== null ? schoolName : "",
                    school_phone: schoolPhone !== null ? schoolPhone : "",
                    school_email: schoolEmail !== null ? schoolEmail : "",
                    website_url: schoolWebsiteUrl !== null ? schoolWebsiteUrl : "",
                    school_address: schoolAddress !== null ? schoolAddress : "",
                    language: schoolLanguage !== null ? schoolLanguage : "",
                    time_zone: schoolTimeZone !== null ? schoolTimeZone : "",
                    updated_by: localStorage.getItem('loginUserId'),
                    school_logo: schoolLogo !== null ? schoolLogo : "",
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                if (response.data.status === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    setSchoolInfoEdit(false);
                    getSchoolProfileDetails();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }
    }

    const [schoolProfileDetails, setSchoolProfileDetails] = useState(null);
    const getSchoolProfileDetails = () => {
        try {
            axios({
                method: 'post',
                url: baseUrl + '/api/get_school_profile',
                data: {
                    id: localStorage.getItem('schoolId'),
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: localStorage.getItem('access_token'),
                },
            }).then(response => {
                console.log(response);
                if (response.data.status === true) {
                    setSchoolProfileDetails(response.data.data[0]);
                    setSchoolId(response.data.data[0].id);
                    setSchoolName(response.data.data[0].school_name);
                    setSchoolEmail(response.data.data[0].school_email);
                    setSchoolPhone(response.data.data[0].school_phone);
                    setSchoolWebsiteUrl(response.data.data[0].website_url);
                    setSchoolAddress(response.data.data[0].school_address);
                    setSchoolLanguage(response.data.data[0].language);
                    setSchoolTimeZone(response.data.data[0].time_zone);
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getSchoolProfileDetails();
    }, []);

    return (
        <section className="Schoolprofile_page">
            <Snackbar className={`snack_total ${messageType === 'success' ?"snackbar_success":"snackbar_failure"}`}
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                    <div>
                        {messageType === 'success' ?
                        <span class="icon-List_Present" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>:                    
                        <span class="icon-List_Absent" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                            <span class="path6"></span>
                        </span>
                        }
                    </div>
                            {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                // ContentProps={{
                //     sx: {
                //         border: "1px solid",
                //         opacity: "1",
                //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         width: 'auto',
                //         minWidth:'auto'
                //     }
                // }}
                autoHideDuration={3000}
            />
            <div className="m-25">
                {
                    schoolInfoEdit === false ?
                        schoolProfileDetails !== null && <Card className="max-w-sm">
                            <div className="per_info_pro">
                                <div>
                                    <h5>Personal Information</h5>
                                </div>
                                <div className="dip_fle">
                                    <button class="profile_cancel" onClick={() => handleShowSchoolInfoEdit(true)}><i className="icon-Student_Edit" />&nbsp;<span>Edit</span></button>
                                </div>
                            </div>
                            <div className="pro_pss_lock">
                                {/* <div> */}
                                {(schoolProfileDetails.school_logo !== null && schoolProfileDetails.school_logo !== "") && <div><Avatar img={baseUrl + '/api/' + schoolProfileDetails.school_logo} bordered /></div>}
                                {/* <Avatar placeholderInitials={schoolProfileDetails.school_name.slice(0, 1).toUpperCase()} bordered /> */}
                                {/* </div> */}
                                <div className="flex_pass_start school_name text-cap">
                                    <h4>{schoolProfileDetails.school_name}</h4>
                                    <p>{schoolProfileDetails.programs}</p>
                                </div>
                            </div>
                            <div className="wid_100 pbb-15 school_infor_pro">
                                <div className="wid_50">
                                    <div className="pro_pss_lock ">
                                        <div className="size_small_image">
                                            <Avatar img={mail} bordered />
                                        </div>
                                        <div className="flex_pass_start">
                                            <h4>School Email</h4>
                                            <p>{schoolProfileDetails.school_email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="wid_50">
                                    <div className="pro_pss_lock ">
                                        <div className="size_small_image">
                                            <Avatar img={call} bordered />
                                        </div>
                                        <div className="flex_pass_start">
                                            <h4>School Phone Number</h4>
                                            <p>{schoolProfileDetails.school_phone}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wid_100 pbb-15 school_infor_pro">
                                <div className="wid_50">
                                    <div className="pro_pss_lock ">
                                        <div className="size_small_image">
                                            <Avatar img={web} bordered />
                                        </div>
                                        <div className="flex_pass_start">
                                            <h4>Website</h4>
                                            <p>{schoolProfileDetails.website_url}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="wid_50">
                                    <div className="pro_pss_lock ">
                                        <div className="size_small_image">
                                            <Avatar img={lang} bordered />
                                        </div>
                                        <div className="flex_pass_star text-cap">
                                            <h4>Language</h4>
                                            <p>{schoolProfileDetails.language}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wid_100 pbb-15 school_infor_pro">
                                <div className="wid_50">
                                    <div className="pro_pss_lock ">
                                        <div className="size_small_image">
                                            <Avatar img={time} bordered />
                                        </div>
                                        <div className="flex_pass_start text-cap">
                                            <h4>Timezone</h4>
                                            <p>{schoolProfileDetails.time_zone}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="wid_50">
                                    <div className="pro_pss_lock ">
                                        <div className="size_small_image">
                                            <Avatar img={loction} bordered />
                                        </div>
                                        <div className="flex_pass_start text-cap">
                                            <h4>Address</h4>
                                            <p>{schoolProfileDetails.school_address}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card> :
                        <Card className="max-w-sm">
                            <div className="per_info_pro">
                                <div>
                                    <h5>School Information</h5>
                                </div>
                                <div className="dip_fle">
                                    <button class="profile_cancel" onClick={() => handleShowSchoolInfoEdit(false)}><span>Cancel</span></button>
                                    <button class="profile_save" onClick={() => updateSchoolInfo()}><span>Save</span></button>
                                </div>
                            </div>
                            <div className="scool_info_list">
                                <p>Your Logo</p>
                                <div className="dis_flex_first pt-15 pos_stu_cus">
                                    <div className="logo_update_stud">
                                        {/* <Avatar img={schoolLogo !== null ? URL.createObjectURL(schoolLogo) : log} /> */}
                                        <Avatar img={((schoolProfileDetails.school_logo !== null && schoolProfileDetails.school_logo !== "") && (schoolLogo === "" || schoolLogo === null)) ? baseUrl + '/api/' + schoolProfileDetails.school_logo : schoolLogo !== null ? URL.createObjectURL(schoolLogo) : log} />
                                    </div>
                                    <div className="totl_dis_change">
                                        <div className="dis_flex">
                                            {
                                                refreshLogo && <p className="change_logo_onbor" style={{ position: 'relative' }}>
                                                    <input
                                                        type="file"
                                                        id="imageInput"
                                                        style={{ width: '100%', height: "revert-layer", margin: '0px' }}
                                                        accept=".jpg, .jpeg, .png, .gif"
                                                        // multiple
                                                        onChange={handleImageChange}
                                                    />
                                                    Change Logo
                                                </p>
                                            }
                                            <p>or</p>
                                            <p className="remove_logo_onbor" style={{ cursor: "pointer" }} onClick={() => handleRemoveLogo()}>Remove Logo</p>
                                        </div>
                                        <p>We recommend a resolution of at least 32x32 and 1:1 aspect ratio</p>
                                    </div>
                                    {validationErrors.schoolLogo && (
                                        <p className="error pos_abo_stu logo_error_mes">{validationErrors.schoolLogo}</p>
                                    )}
                                </div>

                                <div className="form-section-default flexc justifySp fwrap">
                                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                                        <label>School Name </label><span className="col_red">*</span>
                                        <div className="">
                                            <input
                                                type="text"
                                                className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                                placeholder="Kendriya Vidyalaya"
                                                value={schoolName}
                                                onChange={(ev) =>
                                                    setSchoolName(ev.target.value)
                                                }
                                                style={{ borderColor: validationErrors.schoolName ? 'red' : '' }}
                                            />
                                        </div>
                                        {validationErrors.schoolName && (
                                            <p className="error pos_abo_stu">{validationErrors.schoolName}</p>
                                        )}
                                    </div>
                                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                                        <label>Email ID </label><span className="col_red">*</span>
                                        <div className="">
                                            <input
                                                type="text"
                                                className={`custom-password-field field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                                placeholder="kendriya_chengalpattu@vidyala.com"
                                                value={schoolEmail}
                                                onChange={(ev) =>
                                                    setSchoolEmail(ev.target.value)
                                                }
                                                style={{ borderColor: validationErrors.schoolEmail ? 'red' : '' }}
                                            />
                                        </div>
                                        {validationErrors.schoolEmail && (
                                            <p className="error pos_abo_stu">{validationErrors.schoolEmail}</p>
                                        )}

                                    </div>
                                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                                        <label>Phone Number</label><span className="col_red">*</span>
                                        <div className="">
                                            <input
                                                type="text"
                                                className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                                placeholder="+91 8845998843"
                                                value={schoolPhone}
                                                onChange={(ev) =>
                                                    setSchoolPhone(ev.target.value)
                                                }
                                                style={{ borderColor: validationErrors.schoolPhone ? 'red' : '' }}
                                            />
                                        </div>
                                        {validationErrors.schoolPhone && (
                                            <p className="error pos_abo_stu">{validationErrors.schoolPhone}</p>
                                        )}
                                    </div>
                                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                                        <label>Website</label>
                                        <div className="">
                                            <input
                                                type="text"
                                                className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                                placeholder="https://kvsangathan.nic.in/"
                                                value={schoolWebsiteUrl}
                                                onChange={(ev) =>
                                                    setSchoolWebsiteUrl(ev.target.value)
                                                }
                                            />
                                        </div>
                                        {/* {validationErrors.schoolWebsiteUrl && (
                                            <p className="error pos_abo_stu">{validationErrors.schoolWebsiteUrl}</p>
                                        )} */}
                                    </div>
                                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                                        <label>Language</label>
                                        <div className="">
                                            <input
                                                type="text"
                                                className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                                placeholder="English (US)"
                                                value={schoolLanguage}
                                                onChange={(ev) =>
                                                    setSchoolAddress(ev.target.value)
                                                }
                                            // style={{ borderColor: validationErrors.schoolLanguage ? 'red' : '' }}

                                            />
                                        </div>
                                        {/* {validationErrors.schoolLanguage && (
                                            <p className="error pos_abo_stu">{validationErrors.schoolLanguage}</p>
                                        )} */}
                                    </div>
                                    <div className="field-label half-field error_mes_cus " style={{ padding: "0px" }}>
                                        <label>Time Zone</label>
                                        <div className="field-value-holder dropbutton school_info_drop_down"                                >
                                            <Dropdown label="Asia/Calcutta">
                                                <Dropdown.Item>Asia</Dropdown.Item>
                                                <Dropdown.Item>Asia</Dropdown.Item>
                                                <Dropdown.Item>Asia</Dropdown.Item>
                                                <Dropdown.Item>Asia</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="field-label full-field error_mes_cus pos_stu_cus">
                                        <label>School Address</label><span className="col_red">*</span>
                                        <div className="">
                                            <input
                                                type="text"
                                                className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input `}
                                                placeholder="No 5, North Street. Chennai -600028"
                                                value={schoolAddress}
                                                onChange={(ev) =>
                                                    setSchoolAddress(ev.target.value)
                                                }
                                                style={{ borderColor: validationErrors.schoolAddress ? 'red' : '' }}

                                            />
                                        </div>
                                        {validationErrors.schoolAddress && (
                                            <p className="error pos_abo_stu">{validationErrors.schoolAddress}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Card>
                }
            </div>
        </section>
    );
}

export default Schoolprofile;