import React, { useState, useRef, useEffect } from "react";
import axios from 'axios';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../common/common";
import '../../common/components/css/style.css';
import "../../index.css";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Dropdown } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import ImportComponent from "../Import/Import";
import { WindmillSpinner } from 'react-spinner-overlay';
import moment from 'moment';
import validator from "validator";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import CommonSidebarFooter from '../../common/layouts/CommonSidebarFooter.jsx';
import { LogoComponent } from "../school/Common.jsx";
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";
import ProgramAddComponent from "../programs/AddProgram.jsx";
import Nodata from "../nodata/Nodata.jsx";

export default function Staffattendance() {

    const navigate = useNavigate();
    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);
    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [foodData, setFoodData] = useState({});
    const [dataHeader, setDataHeader] = useState([]);
    const [foodHeaderdata, setFoodHeaderData] = useState([]);
    const [foodLoading, setFoodLoading] = useState(false);

    const [filterValue, setFilterValue] = useState('');
    const handleInputChange = async (event) => {
        setFilterValue(event.target.value);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    search: event.target.value.trim(),
                    // programs_id: localStorage.getItem('programId'),
                    // list_status: 1
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
    /* 31-07-2024 */
    const clearInput = () => {
        setFilterValue('');
        fetchAttendanceData();
    };
    const fetchAttendanceData = () => {
        setFoodLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    search: ""
                    // programs_id: localStorage.getItem('programId'),
                    // list_status: 1
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setAttendanceDate(response.data.current_static_new);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const [selectedStaff, setselectedStaff] = useState([]);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allStudentIds = foodHeaderdata.map(student => student.id);
            setselectedStaff(allStudentIds);
        } else {
            setselectedStaff([]);
        }
    };

    const handleSelectStudent = (studentId) => {
        setselectedStaff(prevSelected =>
            prevSelected.includes(studentId)
                ? prevSelected.filter(id => id !== studentId)
                : [...prevSelected, studentId]
        );
    };

    const [error, setError] = useState(null);
    const todayDate = new Date();
    const [attendanceDate, setAttendanceDate] = useState(todayDate.toISOString().split('T')[0]);
    const addAttendance = () => {
        const today = new Date();
        if (selectedStaff.length === 0) {
            // setError("Please Select Student")
            // setOpen(true);
            setSnackbarMessage("Please Select Student");
            setMessageType('error');
            handleSnackbarOpen();
        } else {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                attendance_date:attendanceDate,
                programs_id: localStorage.getItem('programId'),
                staff_ids: selectedStaff,
                attendance_status: attendanceStatus,
                status: 1,
                created_by: localStorage.getItem('schoolId'),
                updated_by: localStorage.getItem('schoolId')
            }
            console.log(postData)
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_add_edit",
                data: postData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setselectedStaff([])
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    handleAttendanceClose();
                    fetchAttendanceData();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }

    }

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // ------11-06----------------
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    // -------------------------19-06-------------------------

    const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
    const [newProgramAddUpdateStatus, setNewProgramAddUpdateStatus] = useState(null);
    const handleOpenNewProgramPopupVisible = (status) => {
        setNewProgramAddUpdateStatus(status);
    }

    const [teachersData, setTeachersData] = useState([]);
    const fetchTeachersData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/teacher_list",
            data: {
                school_id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTeachersData(response.data.data);
                console.log(response);
            }
        });
    }

    // -------------27-06-----------

    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [importstatus, setimportstatus] = useState(false);

    const importStudents1 = () => {
        setimportstatus(true);
    };

    const handleImportStatus = (status) => {
        if (status == true) {  
            fetchAttendanceData();
            setimportstatus(false);
        }
    };

    // ----- 04-07  active date----------
    const inputDateObj = moment("Thu Jul 04 2024", 'ddd MMM DD YYYY').toDate();
    const currentDateObj = new Date();
    const activeFormatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const formattedCurrentDate = activeFormatDate(currentDateObj);


    // -------------------------06-07------------------------------------
    // ------------------------add new staff----------------   

    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        dob: "",
        joining_date: "",
        address: "",
        role_id: "",
        profile_image: null
    });

    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };
    const handleProgramsChange = (programId) => {
        setSelectedPrograms((prevSelected) =>
            prevSelected.includes(programId)
                ? prevSelected.filter((id) => id !== programId)
                : [...prevSelected, programId]
        );
    };

    const fetchRoles = async () => {
        try {
            const response = await axios.post(
                baseUrl + "/api/get_role",
                {},
                {
                    headers: {
                        "Authorization": localStorage.getItem('access_token')
                    }
                }
            );
            setRoles(response.data.data);
        } catch (error) {
            console.error("Error fetching roles:", error);
            setError("Failed to fetch roles");
        }
    };

    const fetchPrograms = async () => {
        try {
            const response = await axios.post(
                baseUrl + "/api/get_programs",
                {
                    school_id: localStorage.getItem("schoolId"),
                },
                {
                    headers: {
                        "Authorization": localStorage.getItem('access_token')
                    }
                }
            );
            setPrograms(response.data.data);
        } catch (error) {
            console.error("Error fetching programs:", error);
        }
    };

    useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    const convertDBDateFormat = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return `${year}-${month}-${day}`;
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const dateFormatList = 'DD-MM-YYYY';

    const ondobChange = (date, dateString) => {
        setUser({ ...user, dob: dateString });
        console.log(date, dateString);
    };

    const ondojChange = (date, dateString) => {
        setUser({ ...user, joining_date: dateString });
        console.log(date, dateString);
    }
    const selectedRoles = roles.find((data) => data.id === user.role_id);

    const firstNameInputRef = useRef(null);
    const emailInputRef = useRef(null);
    const pnoneInputRef = useRef(null);
    const dobInputRef = useRef(null);
    const joingInputRef = useRef(null);
    const adressInputRef = useRef(null);
    const roleInputRef = useRef(null);
    const programsInputRef = useRef(null);
    const submitButtonRef = useRef(null);
    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === "Enter") {
            event.preventDefault();
            console.log("Next input ref:", nextInputRef);
            if (nextInputRef && nextInputRef.current) {
                nextInputRef.current.focus();
            } else {
                console.log("Next input ref is null");
            }
        }
    };

    const [validationErrors, setValidationErrors] = useState({
        first_name: "",
        email: "",
        phone: "",
        dob: "",
        joining_date: "",
        address: "",
        role_id: "",
        programs: "",
        permissions_id: ""
    });
    const validateForm = () => {
        let errors = {};
        if (user.first_name === "") {
            errors.first_name = "Staff name cannot be empty";
        }
        if (user.phone.trim() === "") {
            errors.phone = "Staff phone number cannot be empty";
        } else if (
            !validator.isMobilePhone(user.phone.trim()) ||
            user.phone.trim().length !== 10
        ) {
            errors.phone = "Please enter valid mobile number";
        }
        if (user.address === "") {
            errors.address = "Staff address cannot be empty";
        }
        if (user.role_id === "" || user.role_id === null) {
            errors.role_id = "Staff role cannot be empty";
        }
        if (user.role_id !== "" || user.role_id !== null) {
            if (user.role_id === 1) {
                if (selectedPrograms.length === 0) {
                    errors.programs = "Staff Programs cannot be empty";
                }
            }
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const [searchQuery, setSearchQuery] = useState('');
    const filteredPrograms = programs.filter((program) =>
        program.program_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const initialBorderColor = {
        role: ""
    };

    const [borderColor, setBorderColor] = useState(initialBorderColor);

    const handleFocus = (borderColor) => {
        if (borderColor === "role") {
            setBorderColor({ ...borderColor, role: "#4D6EE3" });
        }
    };

    const handleBlur = () => {
        setBorderColor(initialBorderColor);
    };

    const onSubmit = (ev) => {
        if (validateForm()) {
            const postData = {
                school_id: localStorage.getItem("schoolId"),
                job_title: "",
                first_name: user.first_name,
                last_name: "",
                gender: "",
                email: user.email,
                phone: user.phone,
                dob: user.dob !== "" ? convertDBDateFormat(user.dob) : "",
                joining_date: user.joining_date !== "" ? convertDBDateFormat(user.joining_date) : "",
                // dob: convertDBDateFormat(user.dob),
                // joining_date: convertDBDateFormat(user.joining_date),
                blood_group: "",
                address: user.address,
                assign_classes: selectedPrograms.join(","),
                role_id: user.role_id,
                permissions_id: selectedPermissions.join(","),
                status: 1,
                created_by: localStorage.getItem('loginUserId'),
                updated_by: localStorage.getItem('loginUserId'),
                created_on: "",
                updated_on: "",
                profile_image: user.profile_image,
            };
            setError(null);
            axios({
                method: "post",
                url: baseUrl + "/api/staff_add",
                data: postData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                if (response["data"]["status"] === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    $(".overlay").removeClass("visible");
                    $(".popup-modal-container").removeClass("visible");
                    setUser({
                        first_name: "",
                        email: "",
                        phone: "",
                        dob: "",
                        joining_date: "",
                        address: "",
                        role_id: "",
                    });
                    fetchAttendanceData();
                    setSelectedPermissions([]);
                    setSelectedPrograms([]);
                    console.log(response);
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside1);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside1);
        };
    }, []);

    const handleClickOutside1 = (event) => {
        if (roleInputRef.current && !roleInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }
    };

    // -------16-07------------------
    // const [staffDataLength, setStaffDataLength] = useState(23);
    const [staffDataLength, setStaffDataLength] = useState(() => Math.floor(window.innerWidth / 80));
    // -------------24-07-------------
    const [isAttendanceVisible, setAttendanceVisible] = useState(false);
    const [attendanceStatus, setAttendanceStatus] = useState(null);
    const handleAttendanceClick = (attendanceStatus) => {
        setAttendanceStatus(attendanceStatus)
        setAttendanceVisible(true);
    };

    const handleAttendanceClose = () => {
        setAttendanceStatus(null)
        setAttendanceVisible(false);
    };

    // 25-07
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setUser({ ...user, profile_image: file });
        setSelectedImage(file);
    };


    useEffect(() => {
        $(document).on("click", "#newStaffCreate", function () {
            setValidationErrors({});
            setUser({
                first_name: "",
                email: "",
                phone: "",
                dob: "",
                joining_date: "",
                address: "",
                role_id: "",
                profile_image: null
            });
            setSelectedPermissions([]);
            setSelectedPrograms([]);
            setSelectedImage(null);
            $(".overlay").addClass("visible");
            $(".popup-modal-container").addClass("visible");
        });
        $(document).on("click", ".popup-student-details .close", function () {
            $(".popup-student-details").removeClass("visible");
        });
        $(document).on("click", ".sp-btn-cancel", function () {
            $(".overlay").removeClass("visible");
            $(".popup-modal-container").removeClass("visible");
        });
        // $(document).on("click", "#newStaffCreate", function () {
        fetchProgramsData();
        fetchTeachersData();
        fetchAttendanceData();
        fetchRoles();
        fetchPrograms();
        //     $(".overlay").addClass("visible");
        //     $(".popup-modal-container").addClass("visible");
        // });
        // $(document).on("click", ".popup-student-details .close", function () {
        //     $(".popup-student-details").removeClass("visible");
        // });
        // $(document).on("click", ".sp-btn-cancel", function () {
        //     setError(null);
        //     $(".overlay").removeClass("visible");
        //     $(".popup-modal-container").removeClass("visible");
        // });


    }, []);

    return (
        <section className="staff_atten_cus">
            <Snackbar className={`snack_total ${messageType === 'success' ?"snackbar_success":"snackbar_failure"}`}
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                    <div>
                        {messageType === 'success' ?
                        <span class="icon-List_Present" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>:                    
                        <span class="icon-List_Absent" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                            <span class="path6"></span>
                        </span>
                        }
                    </div>
                            {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                // ContentProps={{
                //     sx: {
                //         border: "1px solid",
                //         opacity: "1",
                //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         width: 'auto',
                //         minWidth:'auto'
                //     }
                // }}
                autoHideDuration={3000}
            />
            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                    <CommonSidebarHeader />
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="name_tag_side">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard" />
                                            <p>Dashboard</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity" />
                                            <p>Activity</p>
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable" />
                                            <p>Calendar</p>
                                        </span>
                                    </li>
                                    <li className="selected">
                                        <span>
                                            <i className="icon-Staff" />
                                            <p>Staff</p>
                                        </span>
                                    </li>
                                    {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                                </ul>
                                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible("add")}>
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data) => {
                                            return (
                                                <li onClick={() => {
                                                    localStorage.setItem("programId", data.id);
                                                    localStorage.setItem("programName", data.program_name);
                                                    navigate('/student_list');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program_1" />
                                                        <p>{data.program_name}</p>
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                                type="spinner-cub"
                                                bgColor="#4D6EE3"
                                                color="#6B6F8C"
                                                title="Loading..."
                                                size={35}
                                            /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    }
                                </ul>
                            </div>
                        </div>
                        <CommonSidebarFooter />
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp exta_pad_10">
                        <div className="ft-wt600 flexc pad_ic_ri">
                            <span className="selected-icon">
                                <i className="icon-Staff" />
                            </span>
                            <p className="program_tit">Staff</p>
                            {/* <i className="icon-More-Icon" /> */}
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc"
                                onClick={() => {
                                    importStudents1();
                                }}>
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button className="createN ft-wt500 mr-lf10 space_icon_pad" id="newStaffCreate">
                                <i class="fa-solid fa-plus"></i> New Staff
                            </button>
                        </div>
                    </div>
                    <div className="sp-header">
                        <nav className="flexc">
                            {/* <div class="sp-list-view flexc hidden"><i class="icon-Attendance-Outline"></i>Student List</div> */}
                            <div className="sp-list-view flexc" onClick={() => { navigate('/staff_list'); }}>
                                <i className="icon-Student-List" />
                                Staff List
                            </div>
                            <div className="sp-list-view flexc selected">
                                <i className="icon-Attandance" />
                                Attendance
                            </div>
                            {/* <div className="sp-list-view flexc">
                                <i className="icon-Calendar-Outline" />
                                Timetable
                            </div> */}
                        </nav>
                        {importstatus === false &&
                            <div className="sp-menu-bar flexc justifySp present_absent_height">
                                <div className="sp-menu icon_div_space pad_30_ex">
                                    {/* <Snackbar
                                    open={open}
                                    onClose={handleClose}
                                    autoHideDuration={3000}
                                    message={error}
                                /> */}
                                    <div className="flexc">
                                        {
                                            selectedStaff.length === 0 ?
                                                <div className="btn-search-bar flexc">
                                                    <i className="icon-Search" />
                                                    {/* 31-07-2024 */}
                                                    <input
                                                        type="text"
                                                        id="simple-search"
                                                        placeholder="Search Staff"
                                                        value={filterValue}
                                                        onChange={handleInputChange}
                                                        className="search-input"
                                                    />
                                                    {/* 31-07-2024 */}
                                                    <i onClick={clearInput} className={`icon-Close ${filterValue ? '' : 'hide-visibility'}`} />
                                                    {/* <i className="icon-Search" />
                                                    <input
                                                        type="search"
                                                        placeholder="Search Staff"
                                                        value={filterValue}
                                                        onChange={handleInputChange}
                                                    /> */}
                                                </div> :
                                                <>
                                                    <button className="sp_mbtn flexc excla_div glo-showdow" onClick={() => { handleAttendanceClick(1); }}>
                                                        <span className="flexc ft-13" style={{ fontSize: "16px" }}>
                                                            <div style={{ fontSize: "16px" }}>
                                                                <span class="icon-Button_Present"></span>
                                                            </div>
                                                        </span>
                                                        <span>Present</span>
                                                    </button>
                                                    <button className="sp_mbtn flexc excla_div glo-showdow" onClick={() => { handleAttendanceClick(2); }}>
                                                        <div style={{ fontSize: "16px" }}>
                                                            <span class="icon-Button_Absent"></span>
                                                        </div>
                                                        <span>Absent</span>
                                                    </button>
                                                    <button className="sp_mbtn flexc excla_div glo-showdow" onClick={() => { handleAttendanceClick(5); }}>
                                                        <div style={{ fontSize: "16px" }}>
                                                            <span class="icon-Button_Leave"></span>
                                                        </div>

                                                        <span>Leave</span>
                                                    </button>
                                                </>
                                        }
                                    </div>
                                    {/* <div className="flexc">
                                    <button className="sp_mbtn flexc" onClick={() => { addAttendance(1); }}>
                                        <span className="flexc ft-13">
                                            <span className="sp-lpdr  icon-Tick color_cha_whi" />
                                        </span>
                                        <span>Present</span>
                                    </button>
                                    <button className="sp_mbtn flexc excla_div" onClick={() => { addAttendance(2); }}>
                                        <span className="notapp-ele color_cha_whi   " />
                                        <span>Absent</span>
                                    </button>
                                    <button className="sp_mbtn flexc" onClick={() => { addAttendance(5); }}>
                                        <span className="cross-ele color_cha_whi leave_ic" />
                                        <span>Leave</span>
                                    </button>
                                    <div className="btn-search-bar flexc">
                                        <i className="icon-Search" />
                                        <input
                                            type="search"
                                            placeholder="Search Staff"
                                            value={filterValue}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        }
                    </div>
                    {importstatus?
                        <>
                            <ImportComponent
                                importStudent={"staff"}
                                handleImportStatus={handleImportStatus}
                                setProgress={setProgress} setIsLoading={setIsLoading}
                            />
                        </>
                        :
                        <div className="he_fix_cus ">
                            {
                                (foodLoading && foodData) ? (
                                    foodData.data.length > 0 ? (
                                        <div className="std-attence">
                                            <div className="attence-top overxy-auto">
                                                <div className="students-add-list">
                                                    <ul className="sp-head sp_sticky defult-date-header">
                                                        <li className="table_input_sty">
                                                            <input
                                                                type="checkbox"
                                                                id="check-all"
                                                                onChange={handleSelectAll}
                                                                checked={selectedStaff.length === foodHeaderdata.length}
                                                            />
                                                            {foodData.staff}
                                                        </li>
                                                        {dataHeader && dataHeader.length > 0 ?
                                                            dataHeader.map((data, index) => (
                                                                <li key={index} className={`${activeFormatDate(moment(data.date_data, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate && 'active-date-header'}`}>{data.date_data}</li>
                                                            )) : <div></div>}
                                                    </ul>
                                                    {foodHeaderdata.map((student, studentIndex) => (
                                                        <React.Fragment key={studentIndex}>
                                                            <ul>
                                                                <li className="table_input_sty truncate">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="sub_select"
                                                                        onChange={() => handleSelectStudent(student.id)}
                                                                        checked={selectedStaff.includes(student.id)}
                                                                    />
                                                                    <span className="ft-500 flexc img_wid_dis">
                                                                        {
                                                                            student?.profile_image !== null ?
                                                                                <img
                                                                                    src={baseUrl + "/api/" + student?.profile_image}
                                                                                    width={40}
                                                                                    alt={student?.first_name}
                                                                                    style={{ borderRadius: "5px" }}
                                                                                /> : <LogoComponent name={student?.name} index={studentIndex} bgColor={student?.staff_color} />
                                                                        }
                                                                        <span
                                                                            className="mr-lf10 fs-13 truncate name_bold text-cap"
                                                                            data-tooltip-id={`name-tooltip-${student?.id}`}
                                                                            data-tooltip-content={student.name}
                                                                            data-tooltip-place="bottom"
                                                                            style={{ cursor: student.name.length > staffDataLength && "pointer" }}
                                                                        >
                                                                            {
                                                                                (student.name && student.name.length <= staffDataLength) ? student.name : `${student.name.slice(0, staffDataLength)}...`
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </li>
                                                                {
                                                                    student.name.length > staffDataLength &&
                                                                    <Tooltip className="custom-tooltip" id={`name-tooltip-${student.id}`} />
                                                                }
                                                                {student.attendance.map((attendanceData, attendanceIndex) => (
                                                                    <React.Fragment key={attendanceIndex}>
                                                                        <li
                                                                            className={`${activeFormatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate ? 'active-date-data' :
                                                                                activeFormatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) > formattedCurrentDate && 'next-date-data'} ${(studentIndex === (foodHeaderdata.length - 1) && activeFormatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate) ? 'active-last-date-data' : ''}`}
                                                                        >
                                                                            {attendanceData.attendance_status === 1 ? (
                                                                                <span className="flexc ft-13">
                                                                                    <div style={{ fontSize: "21px" }}>
                                                                                        <span class="icon-List_Present">
                                                                                            <span class="path1"></span>
                                                                                            <span class="path2"></span>
                                                                                        </span>
                                                                                    </div>
                                                                                </span>
                                                                            ) : attendanceData.attendance_status === 2 ? (
                                                                                <div>
                                                                                    <span class="icon-List_Absent" style={{ fontSize: "21px" }}>
                                                                                        <span class="path1"></span>
                                                                                        <span class="path2"></span>
                                                                                        <span class="path3"></span>
                                                                                        <span class="path4"></span>
                                                                                        <span class="path5"></span>
                                                                                        <span class="path6"></span>
                                                                                    </span>
                                                                                </div>
                                                                            ) : attendanceData.attendance_status > 2 ? (
                                                                                <div >
                                                                                    <span class="icon-List_Leave" style={{ fontSize: "21px" }}>
                                                                                        <span class="path1"></span>
                                                                                        <span class="path2"></span>
                                                                                        <span class="path3"></span>
                                                                                        <span class="path4"></span>
                                                                                    </span>
                                                                                </div>
                                                                            ) : null}
                                                                        </li>
                                                                    </React.Fragment>
                                                                ))}
                                                            </ul>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // foodData.status === false && (
                                        <Nodata contentType={filterValue === "" ? "staffList" : "staffSearch"} />
                                        // <div className="no_bor_data">
                                        // <center><div className="sp-title flex-3">{foodData.message}</div></center>
                                        // </div>
                                        // )
                                    )
                                ) : (
                                    <div className="data-loader extracss_load">
                                        {/* <Loader
                                        type="spinner-cub"
                                        bgColor="#4D6EE3"
                                        color="#6B6F8C"
                                        title="Loading..."
                                        size={35}
                                        /> */}
                                        <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                        <p>Loading...</p>
                                    </div>
                                )
                            }
                        </div>
                    }

                </div>
                {/* / Create NewStaff / */}
                <div className="popup-modal-container">
                    <div className="popup-wrap">
                        <h2 className="ft-wt700">Create New Staff</h2>
                        <div className="sp-pop-header">
                            <h3 className="ft-wt600">Staff Details</h3>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <h3 className="ft-wt600">Roles &amp; Permissions</h3>
                                    <div className="roll_mar_top">
                                        <label>Role<span className="col_red">*</span></label>
                                        <div
                                            className="field-value-holder dropbutton"
                                            ref={roleInputRef}
                                            style={{
                                                width: "100%",
                                                borderColor: validationErrors.role_id ? "red" : borderColor.role,
                                            }}
                                            onKeyDown={(event) =>
                                                handleKeyPress(event, submitButtonRef)
                                            }
                                            onFocus={() => handleFocus("role")}
                                            onBlur={handleBlur}
                                            tabIndex="0"
                                        >
                                            <Dropdown
                                                label={
                                                    <span
                                                        style={{
                                                            fontSize: "0.8825em",
                                                            color: selectedRoles ? '#1B252F' : '#292929',
                                                            justifyContent: "flex-start"
                                                        }}
                                                    >
                                                        {selectedRoles ? selectedRoles.role_name : "Select Role"}
                                                    </span>
                                                }
                                                style={{ width: "100%" }}
                                            >
                                                {roles.map((item) => (
                                                    <Dropdown.Item
                                                        key={item.id}
                                                        className={`${selectedRoles && selectedRoles.id === item.id ? "selected-item dd_select" : ""
                                                            }`}
                                                        icon={selectedRoles && selectedRoles.id === item.id ? HiCheck : null}
                                                        onClick={() => { setUser({ ...user, role_id: item.id }); setSelectedPrograms([]) }}
                                                    >
                                                        {item.role_name}
                                                    </Dropdown.Item>
                                                ))}

                                            </Dropdown>
                                        </div>
                                        {validationErrors.role_id && (
                                            <p className="error pos_abo_stu">{validationErrors.role_id}</p>
                                        )}
                                    </div>

                                </div>
                                <div className=" full-field ">
                                    {selectedRoles && (
                                        <div className="field-label mar_top_roll full-field error_mes_cus">
                                            {selectedRoles.role_name === "Administrator" && (
                                                <div>
                                                    <label>Permissions</label>
                                                    <div className="label-permissions mar_ful_permison">
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Student & Staff</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Full Access to all features</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Program</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Fees</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Admin Control</button>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedRoles.role_name === "Teacher" && (
                                                <div>
                                                    <label>Permissions</label>
                                                    <div className="label-permissions mar_ful_permison">
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Student</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Full Access to all features</button>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedRoles.role_name === "Security" && (
                                                <div>
                                                    <label>Permissions</label>
                                                    <div className="label-permissions mar_ful_permison">
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Scan QR</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Add Visitor</button>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedRoles.role_name === "Driver" && (
                                                <div>
                                                    <label>Permissions</label>
                                                    <div className="label-permissions mar_ful_permison">
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Pickup Detail</button>
                                                    </div>
                                                </div>
                                            )}
                                            {selectedRoles.role_name === "Helper" && (
                                                <div>
                                                    <label>Helper have below permissions</label>
                                                    <div className="label-permissions mar_ful_permison">
                                                        <button className="sp-selected-bt ft-wt600 ft-13 ">Scan QR</button>
                                                        <button className="sp-selected-bt ft-wt600 ft-13">Pickup Details</button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Staff Name<span className="col_red">*</span><span></span></label>
                                    <div className="">
                                        <input
                                            ref={firstNameInputRef}
                                            type="text"
                                            value={user.first_name}
                                            onChange={(ev) =>
                                                setUser({ ...user, first_name: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, programsInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.first_name ? 'error' : ''}`}
                                            placeholder="Enter full name"
                                        />
                                    </div>
                                    {validationErrors.first_name && (
                                        <p className="error pos_abo_stu">{validationErrors.first_name}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus cus_pro but_foc_cus pos_stu_cus">
                                    <label>Programs{selectedRoles && selectedRoles.id === 1 && <span className="col_red">*</span>}</label>
                                    <div className={`field-value-holder`} style={{ position: 'relative', borderColor: validationErrors.programs ? "red" : "#d9dce6" }}>
                                        <button
                                            id="dropdownSearchButton"
                                            ref={programsInputRef}
                                            className={`${validationErrors.programs ? 'error' : ''}`}
                                            onClick={toggleDropdown}
                                            onKeyPress={(event) => handleKeyPress(event, pnoneInputRef)}
                                            type="button"
                                            disabled={selectedRoles && selectedRoles.id === 1 ? false : true}
                                            style={{
                                                position: "relative",
                                                width: '100%',
                                                textAlign: "start",
                                                height: "40px",
                                                fontSize: "13px",
                                                color: "#292929",
                                                padding: selectedPrograms.length !== 0 ? "0px" : "7px 0.75rem"
                                            }}
                                        >
                                            <div className="dis_pro_flex">
                                                <div className="pro_wid_style">
                                                    {selectedPrograms.length !== 0
                                                        ? selectedPrograms.slice(0, 3).map((selectedId) => {
                                                            const program = programs.find((p) => p.id.toString() === selectedId);
                                                            if (program) {
                                                                return (
                                                                    <button
                                                                        key={program.id}
                                                                        className="sp-selected-bt ft-wt600 ft-13 mr-2 truncate"
                                                                        style={{ margin: "5px" }}
                                                                        onClick={(e) => e.stopPropagation()}
                                                                    >
                                                                        {program.program_name}
                                                                    </button>
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                        : "Selected Programs"
                                                    }
                                                </div>
                                                {
                                                    selectedPrograms.length > 3 && <div><p className="pro_more_text">{`+${selectedPrograms.length - 3} more`}</p></div>
                                                }
                                            </div>
                                            <div className="drop_ic_cus">
                                                <i class="fa-solid fa-chevron-down"></i>
                                            </div>
                                        </button>
                                        {dropdownOpen && (
                                            <div id="dropdownSearch"
                                                ref={dropdownRef}
                                                style={{ position: 'absolute', zIndex: '999', backgroundColor: 'white', width: '100%' }}>
                                                <ul className=" px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200 back_white_cus" aria-labelledby="dropdownSearchButton">
                                                    <div className="field-value-holder custamize_color_place" style={{ position: 'relative', width: '100%', marginTop: "0px" }}>
                                                        <i
                                                            className="icon-Search"
                                                            style={{
                                                                position: 'absolute',
                                                                left: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                pointerEvents: 'none',
                                                            }}
                                                        ></i>
                                                        <input
                                                            style={{ width: '100%', height: '40px', paddingLeft: '35px' }}
                                                            type="search"
                                                            id="input-group-search"
                                                            value={searchQuery}
                                                            onChange={(e) => setSearchQuery(e.target.value)}
                                                            placeholder="Search"
                                                        />
                                                    </div>
                                                    {filteredPrograms.length !== 0 ? filteredPrograms.map((item, index) => (
                                                        <li key={item.id}>
                                                            <div className={selectedPrograms.includes(item.id.toString()) ? "program_checked flex items-center pad_15 rounded hover:bg-gray-100 dark:hover:bg-gray-600" : "flex items-center pad_15 rounded hover:bg-gray-100 dark:hover:bg-gray-600"}>
                                                                <input
                                                                    id={`checkbox-item-${item.id}`}
                                                                    type="checkbox"
                                                                    checked={selectedPrograms.includes(item.id.toString())}
                                                                    onChange={(e) => handleProgramsChange(item.id.toString(), e.target.checked)}
                                                                //className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                                />
                                                                <label htmlFor={`checkbox-item-${item.id}`} className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                                                                    {item.program_name}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )) : <li>Data Not Found</li>}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    {validationErrors.programs && (
                                        <p className="error pos_abo_stu">{validationErrors.programs}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Phone Number<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={pnoneInputRef}
                                            type="text"
                                            value={user.phone}
                                            onChange={(ev) =>
                                                setUser({ ...user, phone: ev.target.value })
                                            }
                                            onKeyPress={(event) => handleKeyPress(event, emailInputRef)}
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.phone ? 'error' : ''}`}
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                    {validationErrors.phone && (
                                        <p className="error pos_abo_stu">{validationErrors.phone}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus pos_stu_cus">
                                    <label>Email</label>
                                    <div className="">
                                        <input
                                            ref={emailInputRef}
                                            type="text"
                                            value={user.email}
                                            onChange={(ev) =>
                                                setUser({ ...user, email: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, adressInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.email ? 'error' : ''}`}
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                    {validationErrors.email && (
                                        <p className="error pos_abo_stu">{validationErrors.email}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus date_pic_img">
                                    <label>Date of Birth</label>
                                    <div className="field-value-holder ">
                                        <DatePicker
                                            // ref={dobInputRef}
                                            placeholder="DD/MM/YYYY"
                                            format={dateFormatList}
                                            onChange={ondobChange}
                                            value={user.dob ? dayjs(user.dob, dateFormatList) : null}
                                        ></DatePicker>
                                    </div>
                                    {validationErrors.dob && (
                                        <p className="error">{validationErrors.dob}</p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus date_pic_img pos_stu_cus">
                                    <label>Joining Date</label>
                                    <div className="field-value-holder">
                                        <DatePicker
                                            // ref={joingInputRef}
                                            placeholder="DD/MM/YYYY"
                                            format={dateFormatList}
                                            onChange={ondojChange}
                                            value={user.joining_date ? dayjs(user.joining_date, dateFormatList) : null}
                                        ></DatePicker>
                                    </div>
                                    {validationErrors.joining_date && (
                                        <p className="error pos_abo_stu">
                                            {validationErrors.joining_date}
                                        </p>
                                    )}
                                </div>
                                <div className="field-label half-field error_mes_cus ">
                                    <label>Staff Photo</label>
                                    <div className="field-value-holder">
                                        <span className="sp-upload-btn flexc justifySp" style={{
                                            color: " #A9AFC3",
                                            fontWeight: "500",
                                            fontSize: "13px"
                                        }}>
                                            {selectedImage === null ? (
                                                // studentId === null ?"Upload":displayUser.profile_image
                                                "Upload"
                                            ) : (
                                                <p style={{
                                                    color: " #1B252F",
                                                    fontWeight: "600",
                                                    fontSize: "13px"
                                                }} className="truncate"> {selectedImage.name}</p>
                                            )}
                                            <button className="sp-file-browse">Browse</button>
                                        </span>
                                        <input
                                            className="input-placeholder1"
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            // ref={imageInputRef}
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, joingInputRef)
                                            }
                                        />
                                    </div>
                                    {/* {validationErrors.profile_image && (
                    <p className="error">
                      {validationErrors.profile_image}
                    </p>
                  )} */}
                                </div>
                                <div className="field-label full-field error_mes_cus pos_stu_cus">
                                    <label>Address<span className="col_red">*</span></label>
                                    <div className="">
                                        <input
                                            ref={adressInputRef}
                                            type="text"
                                            value={user.address}
                                            onChange={(ev) =>
                                                setUser({ ...user, address: ev.target.value })
                                            }
                                            onKeyPress={(event) =>
                                                handleKeyPress(event, roleInputRef)
                                            }
                                            className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.address ? 'error' : ''}`}
                                            placeholder="Address"
                                        // defaultValue={""}
                                        />
                                    </div>
                                    {validationErrors.address && (
                                        <p className="error pos_abo_stu">{validationErrors.address}</p>
                                    )}
                                </div>
                            </div>
                            {/* <h3 className="ft-wt600 mt-30">Roles &amp; Permissions</h3>
              <div className="field-label half-field error_mes_cus pos_stu_cus">
                <label>Role<span className="col_red">*</span></label>
                <div
                  className="field-value-holder dropbutton"
                  ref={roleInputRef}
                  style={{
                    width: "100%",
                    borderColor: validationErrors.role_id ? "red" : borderColor.role,
                  }}
                  onKeyDown={(event) =>
                    handleKeyPress(event, submitButtonRef)
                  }
                  onFocus={() => handleFocus("role")}
                  onBlur={handleBlur}
                  tabIndex="0"
                >
                  <Dropdown
                    label={
                      <span
                        style={{
                          fontSize: "0.8825em",
                          color: selectedRoles ? '#1B252F' : '#292929',
                          justifyContent: "flex-start"
                        }}
                      >
                        {selectedRoles ? selectedRoles.role_name : "Select Role"}
                      </span>
                    }
                    style={{ width: "100%" }}
                  >
                    {roles.map((item) => (
                      <Dropdown.Item
                        key={item.id}
                        className={`${selectedRoles && selectedRoles.id === item.id ? "selected-item dd_select" : ""
                          }`}
                        icon={selectedRoles && selectedRoles.id === item.id ? HiCheck : null}
                        onClick={() => setUser({ ...user, role_id: item.id })}
                      >
                        {item.role_name}
                      </Dropdown.Item>
                    ))}

                  </Dropdown>
                </div>
                {validationErrors.role_id && (
                  <p className="error pos_abo_stu">{validationErrors.role_id}</p>
                )}
              </div>
              {selectedRoles && (
                <div className="field-label full-field error_mes_cus">
                  {selectedRoles.role_name === "Administrator" && (
                    <div>
                      <label>Admin have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Student & Staff</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Full Access to all features</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Program</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Fees</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Admin Control</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Teacher" && (
                    <div>
                      <label>Teacher have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Student</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Full Access to all features</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Security" && (
                    <div>
                      <label>Security have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Scan QR</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Add Visitor</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Driver" && (
                    <div>
                      <label>Driver have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Pickup Detail</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Helper" && (
                    <div>
                      <label>Helper have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Scan QR</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Pickup Details</button>
                      </div>
                    </div>
                  )}
                </div>
              )} */}
                        </div>
                    </div>
                    <div className="sp-bottom-sticky">
                        <button className="sp-btn-create" ref={submitButtonRef} onClick={onSubmit}>
                            Create
                        </button>
                        <button className="sp-btn-cancel">Cancel</button>
                    </div>
                </div>
                {/* Attendance Modal */}
                {
                    isAttendanceVisible && (
                        <div
                            id="recycle-popup"
                            tabIndex={-1}
                            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
                        >
                            <div className="relative w-full max-w-xs max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">   <div className="p-4 md:p-5 text-center">
                                    <div className="leave_round_but rounded inline-flex alignC justify-center">
                                        <i className="icon-Info_Modal" />
                                    </div>
                                    <p className="ft-wt600 ft-16 mb-4 mt-3">Mark as {attendanceStatus === 1 ? "Present" : attendanceStatus === 2 ? "Absent" : "Leave"}</p>
                                    <h3 className="mb-5 font-normal text-sm">
                                        Are you sure you want to update the staff attendance
                                    </h3>
                                    <div className="att-popup">
                                        <button
                                            onClick={handleAttendanceClose}
                                            type="button"
                                            className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => addAttendance()}
                                            type="button"
                                            className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                                        >
                                            Yes, Proceed
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/* Create New Program */}
                <ProgramAddComponent
                    newProgramPopupVisible={newProgramPopupVisible}
                    setNewProgramPopupVisible={setNewProgramPopupVisible}
                    teachersData={teachersData}
                    setProgramsData={setProgramsData}
                    newProgramAddUpdateStatus={newProgramAddUpdateStatus}
                    setNewProgramAddUpdateStatus={setNewProgramAddUpdateStatus}
                />
            </div>

        </section>
    );
}
