import React, { useState } from "react";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Grid } from "@mui/material";
import { differenceInDays, parseISO } from 'date-fns';
import { baseUrl } from "../../common/common";




export default function DefaultLayout() {

  const navigate = useNavigate();
  // const checkReset = () => {
  //   const lastReset = localStorage.getItem('lastReset');
  //   const today = new Date();

  //   if (!lastReset || differenceInDays(today, parseISO(lastReset)) >= 1) {
  //     localStorage.setItem('lastReset', today.toISOString());
  //     return true;
  //   }
  //   return false;
  // };

  // useEffect(() => {
  //   if (checkReset()) {
  //     navigate("/signout")
  //   }
  // }, []);
  // const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const checkServerStatus = async () => {
    try {
      const response = await fetch(baseUrl);
      if (!response.ok) {
        navigate("/maintenance");
      }
    } catch (error) {
      console.log(error+'Error checking server status.');
    }
  };

  useEffect(() => {
    checkServerStatus();
  }, []);


  return (
    <>
      <Header />
        <main>
          {/* <Grid container>
            <Grid item>
              <CommonSidebar />
            </Grid>
            <Grid item xs={12}>
              <Outlet />
            </Grid>
          </Grid> */}
          {/* <div className="text-cap"> */}
            <Outlet />
          {/* </div> */}          
        </main>
      <Footer />
    </>
  );
}
