import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Signup from "../../pages/signup/Signup";
import DefaultLayout from "../../common/layouts/Defaultlayout";
import SchoolInfo from "../../pages/signup/SchoolInfo";
import StudentList from "../../pages/school/StudentList";
import StaffList from "../../pages/school/StaffList";
import Activity from "../../pages/activity/Activity";
import Staffattendance from "../../pages/staffattendance/Staffattendance";
import Dashboard from "../../pages/dashboard/Dashboard";
import Signin from "../../pages/signin/Signin";
import NewPassword from "../../pages/signin/NewPassword";
import Recovery from "../../pages/signin/Recovery";
import Signout from "../../pages/signout/Signout";
import Forgotpassword from "../../pages/signin/Forgotpassword";
import Sleep from "../../pages/sleep/Sleep";
import Food from "../../pages/food/Food";
import StudentAttendance from "../../pages/studentattendance/StudentAttendance";
import StudentTimetable from "../../pages/studenttimetable/StudentTimetable";
import EventCalendar from "../../pages/calendar/EventCalendar";
import PrivateRoute from "./PrivateRoute";
import RedirectIfLoggedIn from "./RedirectIfLoggedIn";
import Settings from "../../pages/settings/Settings";
import Nodata from "../../pages/nodata/Nodata.jsx";

const router = createBrowserRouter([
    {
        path: "",
        element: <DefaultLayout />,
        children: [
            {
                path: "/",
                element: <RedirectIfLoggedIn />,
            },
            {
                path: "*",
                element: <Nodata  contentType={"gatewayError"}/>,
            },
            {
                path: "/maintenance",
                element: <Nodata  contentType={"maintenance"}/>,
            },
            {
                path: "/signup",
                element: <Signup />,
            },
            {
                path: "/signin",
                element:<Signin />,
            },
            {
                path: "/signout",
                element: <Signout />,
            },
            {
                path: "/forgot_password",
                element: <Forgotpassword />,
            },
            {
                path: "/new_password",
                element: <NewPassword />,
            },
            {
                path: "/recovery",
                element: <Recovery />,
            },
            {
                path: "/school_info",
                element: <SchoolInfo />,
            },
            {
                path: "/student_list",
                element: <PrivateRoute element={<StudentList />} />,
            },
            {
                path: "/staff_list",
                element: <PrivateRoute element={<StaffList />} />,
            },
            {
                path: "/activity",
                element: <PrivateRoute element={<Activity />} />,
            },
            {
                path: "/staff_attendance",
                element: <PrivateRoute element={<Staffattendance />} />,
            },
            {
                path: "/dashboard",
                element: <PrivateRoute element={<Dashboard />} />,
            },
            {
                path: "/sleep",
                element: <PrivateRoute element={<Sleep />} />,
            },
            {
                path: "/food",
                element: <PrivateRoute element={<Food />} />,
            },
            {
                path: "/student_attendance",
                element: <PrivateRoute element={<StudentAttendance />} />,
            },
            {
                path: "/student_timetable",
                element: <PrivateRoute element={<StudentTimetable />} />,
            },
            {
                path: "/calendar",
                element: <PrivateRoute element={<EventCalendar />} />,
            },{
                path: "/setting",
                element: <Settings />,
            }
        ],
    }
]);

export default router;
