import React from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { baseUrl } from "../../common/common.jsx";
import nodata from "./images/nodata.svg";
import nodatact from "./images/nodatact.svg";
import maintanence from "./images/maintanence.svg";
import oops from "./images/oops.svg";
import search from "./images/search.svg";

export default function Nodata({contentType}) {
    
    const location = useLocation();
    const navigate = useNavigate();
    const checkServerStatus = async () => {
      try {
        const response = await fetch(baseUrl);
        if (!response.ok) {
          navigate("/maintenance");
        }else{
          navigate("/"); 
        }
      } catch (error) {
        console.log(error+'Error checking server status.');
      }
    };

    return (
        <section>
            <div className="Nodata_page nodata_center_pos">
            {(() => {
                switch (contentType) {
                    case 'upcomingBirthday':
                    case 'upcomingHolidays':
                    case 'leaveApplied':
                        return (
                            <div className="nodata_dashevent_style">
                                <img src={nodata} />
                                <h3>No events</h3>
                            </div>
                        );
                    case 'recentActivity':
                    case 'studentsActivityTime':
                        return (
                            <div className="nodata_dashnono_style">
                                <img src={nodatact} />
                                <h3>No Activity</h3>
                            </div>
                        );
                    case 'studentList':
                        return (
                            <div className="nodata_Student_style">
                               <img src={nodata} />
                               <h3>No Student found</h3>
                            </div>
                           );
                    case 'staffList':
                        return (
                         <div className="nodata_Student_style">
                            <img src={nodata} />
                            <h3>No Staff found</h3>
                         </div>
                        );
                    case 'studentSearch':
                    case 'staffSearch':
                        return (
                            <div className="nodata_search_style">
                                <img src={search} />
                                <h3>No result</h3>
                                <p>User not available in this name</p>
                            </div> 
                        );
                    case 'activityList':
                        return (
                            <div className="nodata_activity_style">
                                <img src={nodatact} />
                                <h3>No Activity Post</h3>
                                <p>Click the button to create your first post!</p>
                                <button class="sp-btn-create">Create Post</button>
                            </div>
                        );
                    case 'maintenance':
                        return (
                          <div className="nodata_maintanence_style">
                            <img src={maintanence} />
                            <h3>We’ll be back soon</h3>
                            <p>Sorry for the inconvinience but we’re prefoming some maintenance at the moment
                                We’ll be back online shortly.</p>
                            <button class="sp-btn-create"onClick={() =>checkServerStatus()}>Refresh</button>
                          </div> 
                        );
                    case 'gatewayError':
                        return (
                        <div className="nodata_oops_style">
                            <img src={oops} />
                            <h3>404 Error!</h3>
                            <p>The Server encountered an internal error or misconfiguration and was 
                            unable to complete your request.</p>
                            <button class="sp-btn-create">Refresh</button>
                         </div>
                        );                                                                                     
                    default:
                        return null;
                }
            })()}
            
                {/* <div className="nodata_Student_style">
                    <img src={nodata} />
                    <h3>No Student found</h3>
                </div> */}
                {/* <div className="nodata_activity_style">
                <img src={nodatact} />
                <h3>No Activity Post</h3>
                <p>Click the button to create your first post!</p>
                <button class="sp-btn-create">Create Post</button>
                </div> */}
                {/* <div className="nodata_maintanence_style">
                <img src={maintanence} />
                <h3>We’ll be back soon</h3>
                <p>Sorry for the inconvinience but we’re prefoming some maintenance at the moment
                    We’ll be back online shortly.</p>
                <button class="sp-btn-create">Refresh</button>
                </div> */}
                {/* <div className="nodata_oops_style">
                <img src={oops} />
                <h3>404 Error!</h3>
                <p>The Server encountered an internal error or misconfiguration and was 
                unable to complete your request.</p>
                <button class="sp-btn-create">Refresh</button>
                </div> */}
                {/* <div className="nodata_search_style">
                <img src={search} />
                <h3>No result</h3>
                <p>User not available in this name</p>
                </div> */}
                {/* <div className="nodata_dashnono_style">
                    <img src={nodatact} />
                    <h3>No Activity</h3>
                </div> */}
               {/* <div className="nodata_dashevent_style">
                    <img src={nodata} />
                    <h3>No events</h3>
              </div> */}
            </div>
        </section>
    );
}
