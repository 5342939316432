import React, { useState, useRef,useEffect } from "react";
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import "../signin/css/style.css";
import panda from "./images/panda.png";
import Snackbar from '@mui/material/Snackbar';
import { baseUrl } from "../../common/common";
import ListItemIcon from '@mui/material/ListItemIcon';

export default function NewPassword() {

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const confirmPasswordRef = useRef(null);
  const submitButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [fileError, setFileError] = useState('');

  const navigate = useNavigate();

  const { encrypt_data } = useParams();
  // const [searchParams, setSearchParams] = useSearchParams();
  // searchParams.get("")

  const [searchParams] = useSearchParams();

  const validatePassword = async (password) => {
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const validateConfirmPassword = async (password, confirmPassword) => {
    if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
      return false;
    } else {
      setConfirmPasswordError("");
      return true;
    }
  };

  const handlePasswordChange = async (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    // await validatePassword(newPassword);
    // await validateConfirmPassword(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = async (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    // await validateConfirmPassword(password, newConfirmPassword);
  };

  const handleKeyPress = (e, nextField) => {
    if (e.key === "Enter") {
      e.preventDefault();
      nextField.current.focus();
    }
  };
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };
  useEffect(() => { }, [snackbarMessage, messageType]);
  const handleClose = (event) => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    const isPasswordValid = await validatePassword(password);
    const isConfirmPasswordValid = await validateConfirmPassword(password, confirmPassword);

    if (isPasswordValid && isConfirmPasswordValid) {
      try {
        const encryptData = window.location.search.substring(1);
        const postData = {
          encrypt_data: encryptData,
          password: password,
        };
        console.log(postData)
        const { data } = await axios.post(baseUrl + "/api/change_password", postData);
        console.log(data, "d");
        if (data.status == true) {
          navigate('/signout');
        } else {
          setSnackbarMessage(data.message);
          setMessageType('error');
          handleSnackbarOpen();
        }
      } catch (error) {
        console.log(error);
      }
    } 
    // else {
    //   setSnackbarMessage("password ");
    //   setMessageType('error');
    //   handleSnackbarOpen();
    // }
  };

  return (
    <section>
            <Snackbar className={`snack_total ${messageType === 'success' ?"snackbar_success":"snackbar_failure"}`}
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                    <div>
                        {messageType === 'success' ?
                        <span class="icon-List_Present" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>:                    
                        <span class="icon-List_Absent" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                            <span class="path6"></span>
                        </span>
                        }
                    </div>
                            {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                // ContentProps={{
                //     sx: {
                //         border: "1px solid",
                //         opacity: "1",
                //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         width: 'auto',
                //         minWidth:'auto'
                //     }
                // }}
                autoHideDuration={3000}
            />
      {/* <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        message={fileError}
        ContentProps={{
          sx: {
            border: "1px 0px 0px 0px",
            opacity: "0px",
            background: "#FD4954",
            border: "1px solid #FD4954"
          }
        }}
      /> */}
      <div className="whole-container sign_page">
        <div className="flex-prop form-wrap">
          <div className="form-container">
            <div className="mrg-cen text-align-c main_panda">
              <img src={panda} style={{ width: "80px" }} width={80} height={100} alt="School Panda" />
              <h1>Create a new password!</h1>
            </div>
            <div className="sign-form mt-40">
              <form onSubmit={handleSubmit}>
                <div className="forms-fields-text error_mes_cus">
                  <label htmlFor="password">Choose new password</label>
                  <div className="icon_input">
                    <input
                      type="password"
                      placeholder="8+ Character required"
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyPress={(e) => handleKeyPress(e, confirmPasswordRef)}
                      style={{ border: passwordError ? "1px solid red" : "" }}
                    />
                    <span className="sp-icon-pw" />
                  </div>
                  {passwordError && <p className="error">{passwordError}</p>}
                </div>
                <div className="forms-fields-text error_mes_cus">
                  <label htmlFor="confirmPassword">Confirm</label>
                  <div className="icon_input">
                    <input
                      type="password"
                      placeholder="8+ Character required"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      ref={confirmPasswordRef}
                      onKeyPress={(e) => handleKeyPress(e, submitButtonRef)}
                      style={{ border: confirmPasswordError ? "1px solid red" : "" }}
                    />
                    <span className="sp-icon-pw" />
                  </div>
                  {confirmPasswordError && <p className="error">{confirmPasswordError}</p>}
                </div>

                <button className="verify-btn" ref={submitButtonRef}>
                  Create Password
                </button>
                <Link to="/signin" className="ms-clr cp">
                  Back to Sign In
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
